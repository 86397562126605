import {
    Button,
    DateRangePickerProps,
    Form,
    FormField,
    Select,
    SelectProps,
    SpaceBetween,
    Textarea
} from "@cloudscape-design/components";
import {OptionDefinition} from '@cloudscape-design/components/internal/components/option/interfaces';
import {API} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {CreateTimeOffInput, TimeOffEvent, UserAssignment} from "../api-types";
import DateRangePickerAndCheck from "../components/DateRangePickerAndCheck";

type TimeOffProps = {
    userID: string;
    onCancel: () => void;
    onSubmit: (data: UserAssignment) => void;
}

const TimeOffForm = (props: TimeOffProps) => {

    const { userID, onCancel, onSubmit } = props;
    const [activityError, showActivityError] = useState<boolean>(false);
    const [datesError, showDatesError] = useState<boolean>(false);
    const [ timeOff, setTimeOff ] = useState<CreateTimeOffInput>({assignee: userID, load:1});
    const [timesOffOptions, setTimesOffOptions] = useState<SelectProps.Option[]>([])
    const [projectDates, setProjectDates] =
            useState<DateRangePickerProps.AbsoluteValue>({
            startDate: "",
            endDate: "",
            type: "absolute",
    });

    useEffect(() => {
        (async () => {
            const { data } = await API.graphql<any>({
                query: `{ listOtherActivities { projectId name } }`,
              });
              const timeOffEvents: TimeOffEvent[] = data.listOtherActivities;
              const timeOffOptions: OptionDefinition[] = timeOffEvents.map((t) => ({value: t.projectId, label: t.name}));
              setTimesOffOptions(timeOffOptions);
        })();
      }, []);

    function submit(timeOff: CreateTimeOffInput) {
        try {
            validate(timeOff);
        } catch (err) {
            return;
        }
        API.graphql<any>({
            query: `mutation ($timeOff: CreateTimeOffInput!) { createTimeOff(timeOff: $timeOff) { comment } }`,
            variables: { timeOff },
        })
        .then(({data}) => {
            onSubmit(data);
        })
        .catch((reason) => {
            console.error(reason);
        });
    }

    function validate(timeOff: CreateTimeOffInput) {
        showActivityError(false);
        showDatesError(false);
        let errors: boolean = false;
        if (!timeOff.projectId) {
            showActivityError(true);
            errors=true;
        }
        if (!timeOff.endDate || !timeOff.startDate) {
            showDatesError(true);
            errors = true;
        }
        if (errors) {
            throw new Error();
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Form
            actions={
            <SpaceBetween direction='horizontal' size='xs'>
                <Button formAction='none' variant='normal' onClick={() => onCancel()}>
                    Cancel
                </Button>
                <Button formAction='none' variant='primary' onClick={() => {submit(timeOff)}}>
                    Submit
                </Button>
            </SpaceBetween>
            }
        >
        <SpaceBetween direction='vertical' size='l'>
            <FormField label={<div className="formfield">Activity <pre className="mandatory">*</pre></div>}
                errorText={activityError && "Activity type is mandatory"}
                >
                <Select
                    options={timesOffOptions}
                    filteringType="auto"
                    selectedOption={timesOffOptions.find((to) => {return to.value === timeOff.projectId}) || null}
                    onChange={ ({ detail }) => {setTimeOff({...timeOff, projectId: detail.selectedOption.value})}}
                />
            </FormField>
            <FormField
                label={<div className="formfield">Dates <pre className="mandatory">*</pre></div>}
                errorText={datesError && "Activity dates are mandatory"}
                >
                <DateRangePickerAndCheck
                    onChange={(dates) => {
                        setTimeOff({...timeOff, startDate: dates.startDate, endDate: dates.endDate});
                        setProjectDates(dates);
                    }}
                    dates={projectDates}
                />
                </FormField>
                <FormField label="Comment" >
                    <Textarea value={timeOff.comment || ""} onChange={({detail}) => {setTimeOff({...timeOff, comment: detail.value})}} />
                </FormField>
        </SpaceBetween>
        </Form>
        </form>
    )
}

 export default TimeOffForm;
