import React, { Fragment, useState } from 'react';
import { format, isWeekend as isWe, addWeeks, startOfWeek, getWeek } from 'date-fns';
import { twoDigits } from '../utils/helpers';

export enum TimeScale {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}

type MetaModalProps = {
  x: number;
  y: number;
  content: any;
};

type week = {
  weekNo: string;
  beginDate: string;
};

export type ChartBar = {
  id: string;
  opacity?: number;
  color: string;
  label: string;
  startDate: Date;
  endDate: Date;
  meta?: any;
};

export type GanttChartProps = {
  onRowClick?: (id: string) => void;
  onBarClick?: (id: string) => void;
  onZoomToDate?: (scale: TimeScale, beginDate: Date, timeUnit: number) => void;
  timeScale?: TimeScale;
  units?: number;
  initialDate?: Date;
  data: {
    id: string;
    label: any;
    meta?: any;
    bars: ChartBar[];
    datesFlagged?: Date[];
    flagMeta?: any;
    loadAndCapacity?: number;
  }[];
  headers?: { id: string; content: any; cells: { id: string; content: any }[] }[];
};

const GanttChart = (props: GanttChartProps) => {
  const {
    headers = [],
    data,
    timeScale = TimeScale.Months,
    units = 12,
    onZoomToDate,
    initialDate = new Date(),
    onBarClick = () => {},
    onRowClick = () => {},
  } = props;
  const css = getStyles({ units });
  const [metaProps, setMetaProps] = useState<MetaModalProps>();

  const [dateCols, firstUnit, afterLastUnit] = generateDateCols(timeScale, units, initialDate);

  const todayColsIndex = getTodayColsIndex(dateCols, timeScale);

  const MetaModal = (props: MetaModalProps) => {
    return <div style={css.metaModel(props.x, props.y)}>{props.content}</div>;
  };

  function isWeekend(dateCol: any): boolean {
    switch (timeScale) {
      case TimeScale.Months:
        return false;
      case TimeScale.Weeks:
        return false;
      case TimeScale.Days:
        return isWe(dateCol);
    }
  }

  function mapBars(bars: ChartBar[]): ChartBar[][] {
    const barRows: ChartBar[][] = [];

    function mapBar(unmappedBar: ChartBar) {
      const uStart = unmappedBar.startDate.setUTCHours(0);
      const uEnd = unmappedBar.endDate.setUTCHours(0);
      const uTimes = { start: uStart, end: uEnd };
      for (const barRow of barRows) {
        let clash = false;
        for (const mappedBar of barRow) {
          const mStart = mappedBar.startDate.setUTCHours(0);
          const mEnd = mappedBar.endDate.setUTCHours(0);
          const mTimes = { start: mStart, end: mEnd };
          const first = uStart < mStart ? uTimes : mTimes;
          const last = uStart < mStart ? mTimes : uTimes;
          if (first.end > last.start) {
            clash = true;
            break;
          }
        }
        if (!clash) {
          barRow.push(unmappedBar);
          return;
        }
      }
      barRows.push([unmappedBar]);
    }

    for (const unmappedBar of bars) {
      mapBar(unmappedBar);
    }

    return barRows;
  }

  return (
    <div style={{ position: 'relative' as any }}>
      <div style={css.chart}>
        {metaProps ? <MetaModal {...metaProps} /> : null}
        <div style={css.stickyHeader}></div>
        <div style={css.stickyHeader}>
          <div style={{ position: 'relative', width: '100%', height: '100%', ...css.headerContent }}>
            {dateCols.map((dateCol: any) => {
              switch (timeScale) {
                case TimeScale.Months:
                  return (
                    <div style={{ ...css.headerCell, ...css.clickable }} key={dateCol.toISOString()}>
                      <a onClick={() => onZoomToDate && onZoomToDate(TimeScale.Weeks, dateCol, 5)}>{getMonthYear(dateCol)}</a>
                    </div>
                  );
                case TimeScale.Weeks:
                  return (
                    <div style={{ ...css.headerCell, ...css.clickable }} key={'weekNo-' + dateCol.weekNo}>
                      <div>
                        <a onClick={() => onZoomToDate && onZoomToDate(TimeScale.Days, dateCol.beginDate, 10)}>
                          <div>week {+dateCol.weekNo.slice(-2)}</div>
                          <div style={css.weekDesc}>{format(dateCol.beginDate, 'do MMM')}</div>
                        </a>
                      </div>
                    </div>
                  );
                case TimeScale.Days:
                  return (
                    <div style={{ ...css.headerCell }} key={dateCol.toISOString()}>
                      {format(dateCol, 'MMM do')}
                    </div>
                  );
                default:
                  return <div></div>;
              }
            })}
            <div style={css.headerMask}>
              <div style={css.lines}>
                {dateCols.map((d: string, i: any) => (
                  <div
                    key={'lines-' + i}
                    style={css.headerLine(todayColsIndex > -1 ? i === todayColsIndex || i === todayColsIndex + 1 : false, isWeekend(d))}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {headers.map((header) => (
          <Fragment key={'headerFragment-' + header.id}>
            <Fragment key={'header-' + header.id}>{header.content}</Fragment>
            <div key={'headerContent-' + header.id} style={css.headerContent}>
              {header.cells.slice(0, units).map((cell) => (
                <Fragment key={'headerCell-' + cell.id}>{cell.content}</Fragment>
              ))}
            </div>
          </Fragment>
        ))}

        {data.map((row, i) => (
          <Fragment key={'chart-row-' + row.id + i}>
            <div
              style={css.side(!(i % 2))}
              onClick={() => onRowClick(row.id)}
              onMouseEnter={(e) => row.meta && setMetaProps({ x: e.clientX + 20, y: e.clientY + 20, content: row.meta })}
              onMouseLeave={() => row.meta && setMetaProps(undefined)}
            >
              <div style={css.sideLabel} data-testid={row.label}>
                {row.label}
              </div>
            </div>
            <div style={css.content(!(i % 2))}>
              {mapBars(
                row.bars
                  .filter(({ startDate, endDate }) => startDate <= endDate)
                  .filter(({ startDate, endDate }) => {
                    switch (timeScale) {
                      case TimeScale.Days:
                      case TimeScale.Months:
                        return !((startDate < firstUnit && endDate < firstUnit) || (startDate >= afterLastUnit && endDate >= afterLastUnit));
                      case TimeScale.Weeks:
                        return !(
                          (getWeekNoLabel(startDate) < firstUnit.weekNo && getWeekNoLabel(endDate) < firstUnit.weekNo) ||
                          (getWeekNoLabel(startDate) >= afterLastUnit.weekNo && getWeekNoLabel(endDate) >= afterLastUnit.weekNo)
                        );
                      default:
                        return true;
                    }
                  })
              ).map((barRow, j) => (
                <div key={'chart-bar-' + j} style={css.bars}>
                  {row.datesFlagged
                    ?.filter((flaggedDate) => {
                      switch (timeScale) {
                        case TimeScale.Days:
                        case TimeScale.Months:
                          return flaggedDate >= firstUnit && flaggedDate < afterLastUnit;
                        case TimeScale.Weeks:
                          return getWeekNoLabel(flaggedDate) >= firstUnit.weekNo && getWeekNoLabel(flaggedDate) < afterLastUnit.weekNo;
                        default:
                          return true;
                      }
                    })
                    .map((flaggedDate) => (
                      <div
                        key={'flagged-' + row.id + flaggedDate}
                        style={css.flags(columnOffset(flaggedDate, dateCols, timeScale), !(i % 2))}
                        onMouseEnter={(e) =>
                          setMetaProps({
                            x: e.clientX + 20,
                            y: e.clientY + 20,
                            content: row.flagMeta || null,
                          })
                        }
                        onMouseLeave={(e) => setMetaProps(undefined)}
                      />
                    )) || null}
                  {barRow.map((bar) => (
                    <div
                      key={'bar-row-' + bar.id}
                      style={{
                        ...css.bar(bar.opacity || 100, bar.color),
                        gridColumnStart: columnOffset(bar.startDate, dateCols, timeScale),
                        gridColumnEnd: columnOffset(bar.endDate, dateCols, timeScale) + 1,
                        gridRow: 1,
                      }}
                      onClick={() => onBarClick(bar.id)}
                      onMouseEnter={(e) => bar.meta && setMetaProps({ x: e.clientX + 20, y: e.clientY + 20, content: bar.meta })}
                      onMouseLeave={(e) => bar.meta && setMetaProps(undefined)}
                    >
                      {bar.label}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
      <div style={css.chartMask}>
        <div></div>
        <div style={css.lines}>
          {dateCols.map((d: string, i: any) => (
            <div key={'lines-' + i} style={css.line(todayColsIndex > -1 ? i === todayColsIndex || i === todayColsIndex + 1 : false, isWeekend(d))} />
          ))}
        </div>
      </div>
    </div>
  );
};

const generateDateCols = (timeScale: TimeScale, units: number, startDate: Date) => {
  units++;
  const [day, month, year] = [startDate.getDate(), startDate.getMonth(), startDate.getFullYear()];
  switch (timeScale) {
    case TimeScale.Months:
      const months = new Array(units).fill(1).map((_, i) => new Date(year, month + i, 1));
      return [months, months[0], months.pop()] as any;
    case TimeScale.Weeks:
      const weeks = new Array(units)
        .fill(1)
        .map((_, i) => addWeeks(startDate, i))
        .map((date) => ({
          weekNo: '' + date.getFullYear() + twoDigits(getWeek(date, { weekStartsOn: 1 })),
          beginDate: startOfWeek(date, { weekStartsOn: 1 }),
        }));
      return [weeks, weeks[0], weeks.pop()] as any;
    case TimeScale.Days:
      const days = new Array(units).fill(1).map((_, i) => new Date(Date.UTC(year, month, day + i, 0, 0, 0, 0)));
      return [days, days[0], days.pop()] as any;
  }
};

const columnOffset = (date: Date, dateCols: Date[] | week[], timeScale: TimeScale) => {
  switch (timeScale) {
    case TimeScale.Days:
      if (date < dateCols[0]) return 1;
      if (date > dateCols[dateCols.length - 1]) return -2;
      const diff = +date - +dateCols[0];
      return Math.ceil(diff / (1000 * 3600 * 24)) + 1;
    case TimeScale.Weeks:
      const week = getWeekNoLabel(date);
      if (week < (dateCols[0] as week).weekNo) return 1;
      if (week > (dateCols[dateCols.length - 1] as week).weekNo) return -2;
      const weekOffset = dateCols.findIndex((col: Date | week) => (col as week).weekNo === week);
      return weekOffset + 1;
    case TimeScale.Months:
      if (date < dateCols[0]) return 1;
      if (date > dateCols[dateCols.length - 1]) return -2;
      const monthYear = getMonthYear(date);
      const monthOffset = (dateCols as Date[]).map(getMonthYear).findIndex((col) => col === monthYear);
      return monthOffset + 1;
  }
};

const getTodayColsIndex = (dateCols: Date[] | week[], timeScale: TimeScale) => {
  const currentDay = new Date();
  switch (timeScale) {
    case TimeScale.Days:
      return dateCols.findIndex((date: any) => date.toDateString() === currentDay.toDateString());
    case TimeScale.Weeks:
      const currentWeek = getWeekNoLabel(currentDay);
      return dateCols.findIndex((dateCol: any) => dateCol.weekNo === currentWeek);
    case TimeScale.Months:
      const currentMonth = getMonthYear(currentDay);
      return dateCols.findIndex((date: any) => getMonthYear(date) === currentMonth);
  }
};

const getWeekNoLabel = (date: Date) => '' + date.getFullYear() + twoDigits(getWeek(date, { weekStartsOn: 1 }));

const getMonthYear = (date: Date) =>
  date
    .toDateString()
    .split(' ')
    .filter((_, i) => i % 2)
    .join(' ');

type StylesProps = {
  units: number;
};

const getStyles = (props: StylesProps) => {
  const { units } = props;

  return {
    chart: {
      position: 'relative' as any,
      display: 'grid',
      gridTemplateColumns: `2fr ${units}fr`,
    },
    chartMask: {
      position: 'absolute' as any,
      height: '100%',
      width: '100%',
      top: 0,
      display: 'grid',
      gridTemplateColumns: `2fr ${units}fr`,
    },
    headerMask: {
      position: 'absolute' as any,
      height: '100%',
      width: '100%',
      top: 0,
      display: 'grid',
      gridTemplateColumns: `${units}fr`,
    },
    side: (even: boolean) => ({
      backgroundColor: even ? '#eee' : '#ddd',
      textAlign: 'center' as any,
      padding: '1em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }),
    content: (even: boolean) => ({
      backgroundColor: even ? '#eee' : '#ddd',
    }),
    headerContent: {
      textAlign: 'center' as any,
      display: 'grid',
      gridTemplateColumns: `repeat(${units}, 1fr)`,
    },
    stickyHeader: {
      background: 'white',
      position: 'sticky' as any,
      width: 'inherit',
      top: '0px',
      zIndex: 6,
      boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.1)',
    },
    headerCell: {
      zIndex: 3,
    },
    clickable: {
      cursor: 'pointer',
    },
    weekDesc: { fontSize: 'smaller' },
    bars: {
      display: 'grid',
      gridTemplateColumns: `repeat(${units}, 1fr)`,
      position: 'relative' as any,
    },
    sideLabel: {
      zIndex: 1,
    },
    bar: (opacity: number, color: string) => ({
      zIndex: 5,
      textAlign: 'center' as any,
      margin: '1em',
      backgroundColor: color,
      opacity: `${opacity / 100 + 0.1}`,
      color: color === 'black' || color === '#336CAF' || color === '#C12806' ? 'white' : 'black',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap' as any,
      cursor: 'pointer',
      borderRadius: '4px',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
    }),
    flags: (colIdx: number, even: boolean) => ({
      zIndex: 2,
      backgroundImage: even
        ? 'repeating-linear-gradient(45deg, #FFC315, #FFC315 10px, #eee 10px, #eee 20px)'
        : 'repeating-linear-gradient(45deg, #FFC315, #FFC315 10px, #ddd 10px, #ddd 20px)',
      width: '100%',
      height: '100%',
      position: 'absolute' as any,
      gridColumnStart: colIdx,
      gridColumnEnd: 'span 1',
    }),
    lines: {
      zIndex: 2,
      display: 'grid',
      height: '100%',
      gridTemplateColumns: `repeat(${units}, 1fr)`,
    },
    headerLines: {
      zIndex: 7,
      display: 'grid',
      height: '100%',
      gridTemplateColumns: `repeat(${units}, 1fr)`,
    },
    line: (today: boolean, weekend: boolean) => ({
      height: '100%',
      backgroundColor: weekend ? '#bbb' : '',
      borderLeft: today ? '5px solid orange' : '1px solid rgba(0, 0, 0, 0.4)',
    }),
    headerLine: (today: boolean, weekend: boolean) => ({
      height: '100%',
      borderLeft: today ? '5px solid orange' : '1px solid rgba(0, 0, 0, 0.4)',
    }),
    metaModel: (x: number, y: number) => ({
      zIndex: 10,
      position: 'fixed' as any,
      backgroundColor: '#dde',
      left: x + 10,
      top: y + 10,
    }),
  };
};

export default GanttChart;
