import React, {useContext, useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {
  Autosuggest,
  Box,
  Button,
  Container,
  DateRangePickerProps,
  Flashbar,
  Form,
  FormField,
  Header,
  Input,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Assignment, Project, ProjectCategory, Team, TeamAssignment, User, UserAssignment } from "../api-types";
import DateRangePickerAndCheck from "../components/DateRangePickerAndCheck";
import AssignmentForm from "./AssignmentForm";
import {v4 as uuidv4} from 'uuid';
import UserContext from "../contexts/UserContext";
import { differenceInWeeks, parse } from "date-fns";

type ProjectFormProps = {
  initialState?: Project;
  onCancel?: () => void;
  onSubmit?: (project: Project) => void;
};

const ProjectForm = (props: ProjectFormProps) => {
  const refDate = new Date();
  const { onSubmit, onCancel, initialState } = props;
  const user = useContext(UserContext);
  const [project, setProject] = useState<Project>({});
  const [teams, setTeams] = useState<Team[]>([]);
  const [projectDates, setProjectDates] =
    useState<DateRangePickerProps.AbsoluteValue>({
      startDate: "",
      endDate: "",
      type: "absolute",
    });
  const [projectNameError, showProjectNameError] = useState<boolean>(false);
  const [projectTypeError, showProjectTypeError] = useState<boolean>(false);
  const [projectRegionError, showProjectRegionError] = useState<boolean>(false);
  const [projectEngIdError, showProjectEngIdError] = useState<boolean>(false);
  const [projectConfidenceError, showProjectConfidenceError] = useState<boolean>(false);
  const [projectDateError, showProjectDateError] = useState<boolean>(false);
  const [projectCategories, setProjectCategories] = useState<ProjectCategory[]>();
  const [regionOptions, setRegionOptions] = useState<SelectProps.Option[]>([]);
  const [projectCategoryOptions, setProjectCategoryOptions] = useState<SelectProps.Option[]>([]);
  const [assignmentErrors, setAssignmentErrors] = useState<any>([]);
  const [engagementManagerOptions, setEngagementManagerOptions] = useState<SelectProps.Option[]>([]);
  const [managerError, showManagerError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const session = await Auth.currentSession();

      const { data : listRegionsData } = await API.graphql<any>({
        query: `{ listRegions }`,
      }, {
        Authorization: session.getIdToken().getJwtToken()
      });
      const regions = listRegionsData.listRegions;
      setRegionOptions(regions.map((region: string) => ({label: region, value: region})));

      const { data : listProjectCategoryData } = await API.graphql<any>( {
        query: `{ listProjectCategories { maxDuration minDuration projectCategory } }`,
      });
      const projectCategories: ProjectCategory[] = listProjectCategoryData.listProjectCategories.sort((a: ProjectCategory, b: ProjectCategory) => a.projectCategory!.localeCompare(b.projectCategory!));
      setProjectCategories(projectCategories);
      const options = projectCategories.map((projectCategory: ProjectCategory) => {
        if (projectCategory.projectCategory === "Other") {
          return {label: "Other", value: "Other"};
        }
        return {label: `${projectCategory.projectCategory} (${projectCategory.minDuration}-${projectCategory.maxDuration} weeks)`, value: projectCategory.projectCategory};
      });
      setProjectCategoryOptions(options);

      const { data } = await API.graphql<any>({
        query: `{ listTeams { teamId name users { userId name} } }`,
      }, {
        Authorization: session.getIdToken().getJwtToken()
      });
      const teams: Team[] = data.listTeams.filter(
          (t: Team) => t.teamId !== "aws"
      );
      setTeams(teams);

      const { data: listEngagementManagersData } = await API.graphql<any>({ query: `{ listEngagementManagers { userId name isEm } }` }, {
        Authorization: session.getIdToken().getJwtToken()
      });
      const ems: User[] = listEngagementManagersData.listEngagementManagers || [];
      const sortedEms = ems?.sort((a, b) => a.name!.localeCompare(b.name!));
      const emOptions = sortedEms?.map(em => ({ label: `${em.name} (${em.userId})`,  value: em.userId }))

      setEngagementManagerOptions(emOptions);

    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (initialState && !initialState.managerId && user.isEm) {
        initialState.managerId = user.attributes?.preferred_username;
      }
      if (initialState && !initialState.projectCategory) {
        initialState.projectCategory = "Other";
      }
      initialState && setProject(JSON.parse(JSON.stringify(initialState))); // deep copy
      initialState && setProjectDates({
          startDate: initialState.startDate!,
          endDate: initialState.endDate!,
          type: "absolute",
      });

      if (user.isEm && (!initialState || (initialState && !initialState.managerId))) {
        setProject( {...project, managerId: user.attributes?.preferred_username});
      }
    })();
  }, [initialState]);

  function submit(project: Project) {
    try {
      validateProject(project);
    } catch (err) {
      return;
    }
    let canSubmit = true;
    if (project.assignments) {
      for (const a of project.assignments) {
        if (a.__typename === 'UserAssignment') {
          if (a.startDate! !== project.startDate!) {
            if (!window.confirm(`Assignment of ${a.user?.userId} starting on ${a.startDate} does not start at the beginning of the engagement (${project.startDate}), is that ok? Please adjust assignment or engagement dates if necessary.`)) {
              canSubmit = false;
            }
          }
          if (a.endDate! !== project.endDate!) {
            if (!window.confirm(`Assignment of ${a.user?.userId} ending on ${a.endDate} does not finish at the end of the engagement (${project.endDate}), is that ok? Please adjust assignment or engagement dates if necessary.`)){
              canSubmit = false;
            }
          }
        }
      }
    }
    if (!project.projectCategory) {
      project.projectCategory = "Other";
    }
    if (project.projectCategory !== "Other") {
      // verify that project length in weeks fit in the projectCategory minDuration/maxDuration
      const projectDuration = differenceInWeeks(parse(project.endDate!, "yyyy-MM-dd", refDate), parse(project.startDate!, "yyyy-MM-dd", refDate), {roundingMethod: "round" });
      console.log(projectDuration);
      const projectCategory = projectCategories?.find(pc => pc.projectCategory === project.projectCategory);
      if (projectCategory && (projectDuration < projectCategory.minDuration! || projectDuration > projectCategory.maxDuration!)) {
        if (!window.confirm(`Engagement duration (${projectDuration} weeks) does not match expected duration for ${project.projectCategory} (${projectCategory.minDuration!}-${projectCategory.maxDuration!} weeks), is that ok? Please adjust category or engagement dates if necessary.`)) {
          canSubmit = false;
        }
      }
    }

    canSubmit && onSubmit && onSubmit(project);
  }

  function validateProject(project: Project) {
    showManagerError(false);
    showProjectNameError(false);
    showProjectTypeError(false);
    showProjectConfidenceError(false);
    showProjectDateError(false);
    showProjectRegionError(false);
    setAssignmentErrors([]);
    let errors: boolean = false;

    if (!project.name) {
      errors = true;
      showProjectNameError(true);
    }
    if (!project.projectType) {
      errors = true;
      showProjectTypeError(true);
    }
    if (project.projectType === "Customer" && !project.engagementId) {
      errors = true;
      showProjectEngIdError(true);
    }
    if (!project.region) {
      errors = true;
      showProjectRegionError(true);
    }
    if (!project.managerId) {
      errors = true;
      showManagerError(true);
    }
    if (project.confidence === undefined || project.confidence > 100 || project.confidence < 0) {
      errors = true;
      showProjectConfidenceError(true);
    }
    if (!project.endDate || !project.startDate) {
      errors = true;
      showProjectDateError(true);
    }
    project.assignments?.forEach(a => {
      if (!a.load || !a.startDate || !a.endDate
        || (a.__typename === 'UserAssignment' && (!a.user || !a.user.userId))
        || (a.__typename === 'TeamAssignment' && (!a.team || !a.team.teamId))) {
          errors = true;
          // TODO : show an error on the good assignment
          setAssignmentErrors([{
            header: `Invalid assignments`,
            type: "error",
            content: 'Assignments must have a load, a start and end date and a team or user',
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setAssignmentErrors([]),
            id: "error_assignment_1"
          }]);
      } else if (a.__typename === 'UserAssignment' && a.load > 1) {
        errors = true;
        setAssignmentErrors([{
          header: `Invalid assignments`,
          type: "error",
          content: 'User assignments cannot have a load higher than one (1)',
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => setAssignmentErrors([]),
          id: "error_assignment_2"
        }]);
      }
    });
    if (errors) {
      throw new Error();
    }
  }

  function updateConfidenceAndAssignments(value: string): void {
    const confidence = parseInt(value);
    const assignments = new Array<TeamAssignment | UserAssignment>();
    project.assignments?.forEach((a) => {
      if (
        a.__typename === "TeamAssignment" &&
        confidence === 100 &&
        project.confidence !== 100
      ) {
        let load = a.load || 1;
        while (load > 0) {
          const assignmentLoad = Math.min(1, load);
          const newAssignment: UserAssignment = {
            __typename: 'UserAssignment',
            id: uuidv4(),
            endDate: a.endDate,
            load: assignmentLoad,
            startDate: a.startDate,
            user: {
              team: {
                teamId: a.team?.teamId,
                name: a.team?.name,
              },
            },
          };
          assignments.push(newAssignment);
          load -= assignmentLoad;
        }
      } else if (
        a.__typename === "UserAssignment" &&
        confidence !== 100 &&
          project.confidence === 100
      ) {
        const newAssignment: TeamAssignment = {
          __typename: 'TeamAssignment',
          id: uuidv4(),
          endDate: a.endDate,
          load: a.load || 1,
          startDate: a.startDate,
          team: {
            teamId: a.user?.team?.teamId,
            name: a.user?.team?.name,
          },
        };
        const existingAssignment = assignments.find((ta) => ta.__typename === 'TeamAssignment' && ta.team?.teamId === newAssignment.team?.teamId);
        if (existingAssignment) {
          existingAssignment.load! += newAssignment.load!;
        } else {
          assignments.push(newAssignment);
        }
      } else {
        console.warn('assignment type undefined');
      }
    });
    setProject({ ...project, confidence, assignments: (assignments && assignments.length > 0 ? assignments : project.assignments) });
  }

  async function checkCapacity(assignment: Assignment) : Promise<string[]> {
    let projectIdToIgnore = project.projectId;
    let overCapacityDates: string[] = [];
    if (!assignment.startDate || !assignment.endDate) return [];
    if (assignment.__typename === 'TeamAssignment') {
      if (!assignment.team || !assignment.team?.teamId) return [];
      const teamId = assignment.team.teamId;

      const query = `{ getLoadAndCapacity(input: {
                  begin: "${assignment.startDate}",
                  end: "${assignment.endDate}",
                  teamIds: ["${teamId}"],
                  projectIdToIgnore: "${projectIdToIgnore}"
                } ) }`;

      const { data } = await API.graphql<any>({ query });
      const loadAndCapacity = JSON.parse(data.getLoadAndCapacity);
      const teamCapacity = loadAndCapacity[teamId].capacity;
      if (loadAndCapacity[teamId]) {
        Object.keys(loadAndCapacity[teamId]).forEach((key) => {
            if (key !== 'capacity' && loadAndCapacity[teamId][key] + (assignment.load?assignment.load:0) > teamCapacity) {
              overCapacityDates.push(key);
            }
        });
      }

    } else if (assignment.__typename === 'UserAssignment') {
      if (!assignment.user || !assignment.user?.userId) return [];
      const userId = assignment.user.userId;

      const query = `{ getLoadAndCapacity(input: {
                  begin: "${assignment.startDate}",
                  end: "${assignment.endDate}",
                  userIds: ["${userId}"],
                  projectIdToIgnore: "${projectIdToIgnore}"
                } ) }`;

      const { data } = await API.graphql<any>({ query });
      const loadAndCapacity = JSON.parse(data.getLoadAndCapacity);
      if (loadAndCapacity[userId]) {
        Object.keys(loadAndCapacity[userId]).forEach((key) => {
            if (loadAndCapacity[userId][key] + (assignment.load?assignment.load:0) > 1) {
              overCapacityDates.push(key);
            }
        });
      }
    } else {
      console.warn('Unknown assignment type');
      return [];
    }
    return overCapacityDates.sort();
  }

  return (
    <Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
              // TODO move this in the EditProject ? how to validate fields...
              <SpaceBetween direction="horizontal" size="xs">
                <Button formAction="none" variant="link" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  data-testid="submit"
                  formAction="none"
                  variant="primary"
                  onClick={() => {
                    submit(project);
                  }}
                >
                  Submit
                </Button>
              </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="s">

            <FormField
              label={<div className="formfield">Engagement name <pre className="mandatory">*</pre></div>}
              errorText={projectNameError && "Engagement name is mandatory"}
            >
              <Input
                data-testid="project-name"
                value={project.name || ""}
                onChange={(e) => { if (e.detail.value.length < 100) setProject({ ...project, name: e.detail.value })} }
              />
            </FormField>

            <FormField
              label={<div className="formfield">Engagement type <pre className="mandatory">*</pre></div>}
              errorText={projectTypeError && "Engagement type is mandatory"}
            >
              <RadioGroup
                data-testid="project-type"
                onChange={({ detail }) => setProject({ ...project, projectType: detail.value, engagementId: detail.value === "Customer" ? project.engagementId : '' })}
                value={project.projectType!}
                items={[
                  { value: "Customer", label: "Customer Facing" },
                  { value: "Internal", label: "Internal Project" },
                ]}
              />
            </FormField>

            <FormField label={<div className="formfield">Project category<pre className="mandatory">*</pre></div>}>
              <Select
                  data-testid="project-category"
                  options={projectCategoryOptions}
                  filteringType="auto"
                  selectedOption={{value: project.projectCategory || "Other"}}
                  onChange={ ({ detail }) => setProject({ ...project, projectCategory: detail.selectedOption.value})}
              />
            </FormField>

            { project.projectType === "Customer" ?
            <FormField
              label={<div className="formfield">Engagement Id <pre className="mandatory">*</pre></div>}
              description="Find it in AWS Investments"
              errorText={projectEngIdError && "Engagement Id is mandatory"}
            >
              <Input
                data-testid="engagementId"
                value={project.engagementId || ""}
                onChange={(e) => { if (e.detail.value.length < 100) setProject({ ...project, engagementId: e.detail.value })} }
              />
            </FormField>
                 : <></>
            }

            <FormField label={<div className="formfield">Engagement Manager <pre className="mandatory">*</pre></div>} errorText={managerError && 'Engagement Manager is mandatory'}>
              <Select
                  data-testid="engagement-manager"
                  options={engagementManagerOptions}
                  filteringType="auto"
                  selectedOption={{value: project.managerId}}
                  onChange={ ({ detail }) => setProject({ ...project, managerId: detail.selectedOption.value})}
              />
            </FormField>

            <FormField
              label={<div className="formfield">Region <pre className="mandatory">*</pre></div>}
              errorText={projectRegionError && "Region is mandatory"}
            >
              <Select
                  data-testid="region"
                  filteringType="auto"
                  selectedOption={project.region ? {label: project.region, value: project.region} : {}}
                  onChange={({ detail }: any) => setProject({...project, region: detail.selectedOption.value})}
                  options={regionOptions}
                  selectedAriaLabel='Selected'
                />
            </FormField>

            <FormField
              label={<div className="formfield">Dates <pre className="mandatory">*</pre></div>}
              errorText={projectDateError && "Engagement dates are mandatory"}
            >
              <DateRangePickerAndCheck
                onChange={(dates) => {
                  setProject({
                    ...project,
                    startDate: dates.startDate,
                    endDate: dates.endDate,
                  });
                  setProjectDates(dates);
                }}
                dates={projectDates}
              />
            </FormField>

            <FormField
              label={<div className="formfield">Confidence <pre className="mandatory">*</pre></div>}
              description="The engagement is committed when confidence is 100%."
              errorText={projectConfidenceError && "Confidence is mandatory (0-100)"}
            >
                <Autosuggest
                  data-testid="confidence"
                  onChange={(e) =>
                    updateConfidenceAndAssignments(e.detail.value)
                  }
                  value={project.confidence?.toString() || ""}
                  options={[
                    { value: "0" },
                    { value: "25" },
                    { value: "50" },
                    { value: "75" },
                    { value: "90" },
                    { value: "100" },
                  ]}
                  enteredTextLabel={(value) => `Use: "${value}"`}
                  placeholder="Enter value"
                  empty="No matches found"
                />
            </FormField>

            <Container header={<Header>Assignments</Header>}>
              <SpaceBetween direction="vertical" size="l">
                <Flashbar items={assignmentErrors} />
                {project.assignments?.map((assignment, i) => (
                  <AssignmentForm
                    key={`assignment-form-${i}`}
                    initialAssignment={{...assignment}}
                    confidence={project.confidence}
                    counter={i}
                    teamList={teams}
                    startDate={project.startDate}
                    endDate={project.endDate}
                    onDatesChanged={async (dates) => {
                      project.assignments![i] = { ...assignment, startDate: dates.startDate, endDate: dates.endDate };
                      setProject({ ...project });
                      return await checkCapacity(project.assignments![i]);
                    }}
                    onLoadChanged={async (newLoad) => {
                      project.assignments![i] = { ...assignment, load: +newLoad };
                      setProject({ ...project });
                      return await checkCapacity(project.assignments![i]);
                    }}
                    onTeamChanged={async (team) => {
                      if (project.confidence !== 100) {
                        const teamAssignment: TeamAssignment = {
                          __typename: 'TeamAssignment',
                          id: assignment.id,
                          startDate: assignment.startDate,
                          endDate: assignment.endDate,
                          load: assignment.load,
                          team
                        }
                        project.assignments![i] = teamAssignment;
                        setProject({ ...project });
                        return await checkCapacity(teamAssignment);
                      } else if (project.confidence === 100) {
                        const userAssignment: UserAssignment = {
                          __typename: 'UserAssignment',
                          id: assignment.id,
                          startDate: assignment.startDate,
                          endDate: assignment.endDate,
                          load: assignment.load,
                          user: {
                            team
                          }
                        }
                        project.assignments![i] = userAssignment;
                        setProject({ ...project });
                        return await checkCapacity(userAssignment);
                      } else {
                        console.warn('Invalid state');
                        return [];
                      }
                    }}
                    onUserChanged={async (teamId, teamName, userId, userName) => {
                      if (project.confidence === 100) {
                        const userAssignment: UserAssignment = {
                          __typename: 'UserAssignment',
                          id: assignment.id,
                          startDate: assignment.startDate,
                          endDate: assignment.endDate,
                          load: assignment.load,
                          user: {
                            userId,
                            name: userName,
                            team: {
                              teamId,
                              name: teamName,
                            }
                          }
                        }
                        project.assignments![i] = userAssignment;
                        setProject({ ...project });
                        return await checkCapacity(userAssignment);
                      } else {
                        console.warn('Invalid state');
                        return [];
                      }
                    }}
                    onCancel={(id) => {
                      setProject({...project, assignments: project.assignments?.filter((a) => (a.id !== id))})
                    }}
                  />
                ))}
                  <Button
                    iconName="add-plus"
                    variant="primary"
                    onClick={() => {
                      const id = uuidv4();
                      let userAssignment: UserAssignment = {
                        __typename: 'UserAssignment',
                        startDate: project.startDate || '',
                        endDate: project.endDate || '',
                        id
                      };
                      let teamAssignment: TeamAssignment = {
                        __typename: 'TeamAssignment',
                        startDate: project.startDate || '',
                        endDate: project.endDate || '',
                        id
                      };

                      setProject({
                        ...project,
                        assignments: [...(project.assignments || []), project.confidence === 100 ? userAssignment : teamAssignment],
                      });
                    }}
                  >
                    Add Assignment
                  </Button>
                </SpaceBetween>
              </Container>
          </SpaceBetween>
        </Form>
      </form>
    </Box>
  );
};

export default ProjectForm;
