import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Projects from './pages/Projects';
import EditProject from './pages/EditProject';
import TeamUsers from './pages/TeamUsers';
import Profile from './pages/Profile';

const Router = () => (
  <Routes>
    <Route path='/' element={<Navigate to='/engagements' replace />} />
    <Route path='/engagements' element={<Projects />} />
    <Route path='/edit-engagement' element={<EditProject />} />
    <Route path='/teams' element={<TeamUsers />} />
    <Route path='/profile' element={<Profile />} />
  </Routes>
);

export default Router;
