import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import {
  Button,
  ContentLayout,
  FormField,
  Header,
  Modal,
  PropertyFilter,
  PropertyFilterProps,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Project, ProjectCategory, User } from "../api-types";
import Chart, {ChartProps} from "../components/Chart";
import {
  confidenceOperator,
  nameOperator,
  emOperator,
  projectFilteringProperties,
  regionOperator,
  typeOperator, categoryOperator
} from "../search-filter";
import {filteringAriaLabels} from "../aria-label";
import {ChartBar} from "../components/GanttChart";
import ProjectDetails from "../components/ProjectDetails";
import {addWeeks, format} from "date-fns";
import {OptionDefinition} from "@cloudscape-design/components/internal/components/option/interfaces";
import TeamDetails from "../components/TeamDetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/root";
import { setQuery as createSetQueryAction } from "../store/filter";
import { PropertyFilterToken } from "@cloudscape-design/collection-hooks";

const Projects = () => {
  const today = new Date();
  const navigate = useNavigate();
  const [timeInterval, setTimeInterval] = useState<Interval>({start: today, end: addWeeks(today, 10)});
  const [rows, setRows] = useState<ChartData>([]);
  const [chosenProjectId, setChosenProjectId] = useState<string>();
  const [chosenTeamId, setChosenTeamId] = useState<string>();
  const [refresh, doRefresh] = useState<boolean>(false);
  const [sortOption, setSortOption] = useState<OptionDefinition>({label: "Start Date", value: 'startDate'});
  const dispatch = useDispatch()
  const query = useSelector((state: RootState) => state.filterState.query);
  const setQuery = (query: PropertyFilterProps.Query) => dispatch(createSetQueryAction(query));
  const [filteringOptions, setFilteringOptions] = useState<
    PropertyFilterProps.FilteringOption[]
  >([]);
  let previousQuery: PropertyFilterProps.Query;

  useEffect(() => {
    (async () => {
      const session = await Auth.currentSession();
      const { data : listRegionsData } = await API.graphql<any>({
        query: `{ listRegions }`,
      }, {
        Authorization: session.getIdToken().getJwtToken()
      });
      const regions = listRegionsData.listRegions;

      let { data : listProjectsData } = await API.graphql<any>({
        query: `{ listProjects { projectId name confidence region projectType } }`,
      }, {
        Authorization: session.getIdToken().getJwtToken()
      });
      const projects: Project[] = listProjectsData.listProjects;

      const { data: listEngagementManagersData } = await API.graphql<any>({ query: `{ listEngagementManagers { userId name isEm } }` }, {
        Authorization: session.getIdToken().getJwtToken()
      });
      const ems: User[] = listEngagementManagersData.listEngagementManagers || [];
      const sortedEms = ems?.sort((a, b) => a.name!.localeCompare(b.name!));
      const emOptions = sortedEms?.map(em => ({ label: `${em.name} (${em.userId})`,  value: em.userId }))

      const filteringOptions: PropertyFilterProps.FilteringOption[] =
        projects.map(({ name }) => ({ propertyKey: "name", value: name! }));

      for (const emOption of emOptions) {
        filteringOptions.push({
          propertyKey: 'em',
          label: emOption.label,
          value: emOption.value!
        })
      }

      const { data : listProjectCategoryData } = await API.graphql<any>( {
        query: `{ listProjectCategories { maxDuration minDuration projectCategory } }`,
      });
      const projectCategories: ProjectCategory[] = listProjectCategoryData.listProjectCategories.sort((a: ProjectCategory, b: ProjectCategory) => a.projectCategory!.localeCompare(b.projectCategory!));
      for (const projectCategory of projectCategories) {
        filteringOptions.push({
          propertyKey: "projectCategory",
          label: projectCategory.projectCategory,
          value: projectCategory.projectCategory
        })
      }

      for (let confidence = 0; confidence <= 75; confidence += 25) {
        filteringOptions.push({
          propertyKey: "confidence",
          value: confidence.toString(),
        });
      }
      filteringOptions.push({
        propertyKey: "projectType",
        value: "Customer",
      });
      filteringOptions.push({
        propertyKey: "projectType",
        value: "Internal",
      });
      filteringOptions.push({
        propertyKey: "confidence",
        value: "90",
      });
      filteringOptions.push({
        propertyKey: "confidence",
        value: "100",
      });
      filteringOptions.push({ propertyKey: "assignment", value: "true" });
      filteringOptions.push({ propertyKey: "assignment", value: "false" });
      for (const region of regions) {
        filteringOptions.push({ propertyKey: "region", value: region });
      }
      setFilteringOptions(filteringOptions);
    })();
  }, []);

  function searchProjectWithQuery(projects: Project[], q: PropertyFilterToken): Project[] {
    return projects.filter((p: Project) => {
      switch (q.propertyKey) {
        case "em":
          return emOperator(p.managerId!)(q.operator)(q.value);
        case "name":
          return nameOperator(p.name!)(q.operator)(q.value);
        case "region":
          return regionOperator(p.region!)(q.operator)(q.value);
        case "projectType":
          return typeOperator(p.projectType!)(q.operator)(q.value);
        case "projectCategory":
          return categoryOperator(p.projectCategory!)(q.operator)(q.value);
        case "confidence":
          return confidenceOperator(p.confidence!)(q.operator)(
              parseInt(q.value)
          );
        case "assignment":
          return (
              p.assignments && (p.assignments.length > 0) === (q.value === "true")
          );
        default:
          return true;
      }
    });
  }

  async function searchProjects() {
    const session = await Auth.currentSession();
    previousQuery = query;
    const { data } = await API.graphql<any>({
      query: `{
      listCommittedProjects(start: "${format(timeInterval.start, 'yyyy-MM-dd')}", end: "${format(timeInterval.end, 'yyyy-MM-dd')}") {
        projectId name confidence projectType projectCategory region startDate endDate managerId engagementId
        assignments {
          ... on UserAssignment {
            __typename
            id
            user {
              userId
              name
              team {
                name
              }
            }
            load endDate startDate
          }
        }
      }

      listPipelinedProjects(start: "${format(timeInterval.start, 'yyyy-MM-dd')}", end: "${format(timeInterval.end, 'yyyy-MM-dd')}") {
        projectId name confidence projectType projectCategory region startDate endDate managerId engagementId
        assignments {
          ... on TeamAssignment {
            __typename
            id
            team {
              teamId
              name
            }
            load endDate startDate
          }
        }
      }
     }`,
    }, {
      Authorization: session.getIdToken().getJwtToken()
    });
    let projects: Project[] = [
      ...data.listCommittedProjects,
      ...data.listPipelinedProjects,
    ];
    if (query.operation === "or" && query.tokens.length > 0) {
      let result: Project[] = [];
      for (const q of query.tokens) {
        result = [...new Set([...result, ...searchProjectWithQuery(projects, q)])]; // to avoid duplicates
      }
      projects = result;
    } else /* and */ {
      query.tokens.forEach((q) => {
        projects = searchProjectWithQuery(projects, q);
      });

    }
    switch (sortOption.value) {
      case 'confidenceup':
          projects = projects.sort((a, b) => {
            if (a.confidence! === b.confidence!)
              return a.startDate!.localeCompare(b.startDate!);
            else
              return a.confidence! - b.confidence!;
          });
          break;
      case 'confidencedown':
        projects = projects.sort((a, b) => {
          if (a.confidence! === b.confidence!)
            return a.startDate!.localeCompare(b.startDate!);
          else
            return b.confidence! - a.confidence!;
        });
        break;
      case 'name':
        projects = projects.sort((a, b) => (a.name!.localeCompare(b.name!)));
        break;
      case 'startDate':
      default:
        projects = projects.sort((a, b) => {
          const compare = a.startDate!.localeCompare(b.startDate!);
          if (compare === 0)
            return a.confidence! - b.confidence!;
          else return compare;
        });
        break;
    }
    setRows(chartDataAdaptor(projects));
  }

  useEffect(() => {
    (async () => {
      if (previousQuery !== query) {
        await searchProjects();
      }
    })();
  }, [query]);

  useEffect(() => {
    (async () => {
      await searchProjects();
    })();
  }, [timeInterval, sortOption]);


  useEffect(() => {
    (async () => {
      if (refresh) {
        await searchProjects();
        doRefresh(false);
      }
    })();
  }, [refresh]);

  function selectTeamOrUser(id: string): void {
    if (id.startsWith("TEAM#"))
        setChosenTeamId(id.split("#")[1]);
  }

  return (
    <ContentLayout
        className="layout"
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <div style={{ minWidth: 200 }}>
              <FormField description="Sort">
                <Select
                  placeholder="Order by..."
                  selectedAriaLabel="Selected"
                  empty="No options"
                  onChange={({ detail }) => setSortOption(detail.selectedOption)}
                  options={[
                    {label: "Confidence ⬈", value: 'confidenceup'},
                    {label: "Confidence ⬊", value: 'confidencedown'},
                    {label: "Engagement Name", value: 'name'},
                    {label: "Start Date", value: 'startDate'},
                  ]}
                  selectedOption={sortOption}
                />
                </FormField>
              </div>
              <div style={{ minWidth: 200 }}>
              <FormField description="Filter">
                 <PropertyFilter
                  query={query}
                  onChange={({ detail }) => setQuery(detail)}
                  filteringOptions={filteringOptions}
                  filteringProperties={projectFilteringProperties}
                  i18nStrings={filteringAriaLabels}
                />
                </FormField>
              </div><FormField description="&nbsp;">
              <Button
                iconName="add-plus"
                variant="primary"
                onClick={() => navigate("/edit-engagement")}
              >
                New Engagement
              </Button>
              </FormField>
            </SpaceBetween>
          }
        >
          Engagements
        </Header>
      }
    >
      <Chart data={rows} onRowClick={setChosenProjectId} onBarClick={(id) => selectTeamOrUser(id)} onDateChanged={(interval) => setTimeInterval(interval)}  />
      {chosenProjectId && (
          <Modal
            id="project-details-modal"
            header="Engagement Details"
            visible={true}
            size="large"
            onDismiss={() => setChosenProjectId(undefined)}
          >
            <ProjectDetails projectId={chosenProjectId} onDelete={ () => { setChosenProjectId(undefined); doRefresh(true)} } />
          </Modal>
      )}
      {chosenTeamId && (
        <Modal header='Team Details' visible={true} onDismiss={() => setChosenTeamId(undefined)}>
          <TeamDetails
            teamId={chosenTeamId}
            onCancel={() => setChosenTeamId(undefined)}
          />
        </Modal>
      )}
    </ContentLayout>
  );
};

function getBarsForProject(project: Project): ChartBar[] {
  if (!project.assignments || project.assignments.length === 0) {
    return [
      {
        id: project.projectId!,
        label: project.confidence!.toString() + "%",
        startDate: new Date(project.startDate!),
        endDate: new Date(project.endDate!),
        opacity: project.confidence!,
        color: "black",
        meta: (
          <div style={{ padding: 10 }}>
            <b>start:</b> {project!.startDate!}
            <br />
            <b>end:</b> {project!.endDate!}
          </div>
        ),
      },
    ];
  }

  let bars: ChartBar[] = [];
  if (
    project.assignments &&
    project.assignments.length > 0
  ) {
    project.assignments.forEach((assignment) => {
      if (assignment.__typename === 'TeamAssignment') {
        bars.push({
          id: `TEAM#${assignment!.team!.teamId!}`,
          label: assignment!.team!.name!,
          startDate: new Date(assignment!.startDate!),
          endDate: new Date(assignment!.endDate!),
          opacity: project.confidence!,
          color: "#EA7228",
          meta: (
            <div style={{ padding: 10 }}>
              <b>load:</b> {assignment!.load}
              <br />
              <b>confidence:</b> {project.confidence}%
              <br />
              <b>start:</b> {assignment!.startDate!}
              <br />
              <b>end:</b> {assignment!.endDate!}
            </div>
          ),
        });
      } else if (assignment.__typename === 'UserAssignment') {
        bars.push({
          id: `USER#${assignment!.user!.userId!}`,
          label: `${assignment!.user!.name!} (${assignment!.user!.team!.name})`,
          startDate: new Date(assignment!.startDate!),
          endDate: new Date(assignment!.endDate!),
          opacity: project.confidence!,
          color: "#336CAF",
          meta: (
            <div style={{ padding: 10 }}>
              <b>load:</b> {assignment!.load}
              <br />
              <b>start:</b> {assignment!.startDate!}
              <br />
              <b>end:</b> {assignment!.endDate!}
            </div>
          ),
        });
      }
    });
  }
  return bars;
}

type ChartData = ChartProps["data"];
function chartDataAdaptor(projects: Project[]): ChartData {
  return projects.map((project) => ({
    id: project.projectId!,
    label: `${project.name!} (${project.projectCategory || "Other"})`,
    meta: (
      <div style={{ padding: 10 }}>
        <b>start date:</b> {project.startDate}
        <br />
        <b>end date:</b> {project.endDate}
      </div>
    ),
    bars: getBarsForProject(project),
  }));
}

export default Projects;
