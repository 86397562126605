import React, {useEffect, useState} from 'react';
import {Assignment, Team, TeamAssignment, User, UserAssignment} from '../api-types';
import {
    Button,
    Container,
    DateRangePickerProps,
    Flashbar,
    FormField,
    Header,
    Input,
    Select,
    SelectProps,
    SpaceBetween
} from '@cloudscape-design/components';
import DateRangePickerAndCheck from '../components/DateRangePickerAndCheck';
import {compareAsc, parse} from 'date-fns';

type AssignmentFormProps = {
    teamList: Team[];
    startDate?: string;
    endDate?: string;
    counter: number;
    initialAssignment?: TeamAssignment | UserAssignment;
    confidence?: number;
    onCancel: (id: string) => void;
    onUserChanged: (teamId: string, teamName: string, userId: string, userName: string) => Promise<string[]>;
    onTeamChanged: (team: Team | undefined) => Promise<string[]>;
    onLoadChanged: (load: number) => Promise<string[]>;
    onDatesChanged: (dates: DateRangePickerProps.AbsoluteValue) => Promise<string[]>;
  };

const AssignmentForm = (props: AssignmentFormProps) => {
    const { teamList, startDate, endDate, counter, initialAssignment, onCancel, onUserChanged, onTeamChanged, onLoadChanged, onDatesChanged, confidence } = props;
    const refDate = new Date();
    const [assignment, setAssignment] = useState<Assignment>();
    const [teams, setTeams] = useState<Team[]>([]);
    const [assignmentWarning, setAssignmentWarning] = useState<any>([]);

    useEffect(() => {
      (async () => {
          initialAssignment && setAssignment(initialAssignment);
          setTeams(teamList);
      })();
  }, [initialAssignment, teamList]);

    function invalidDate(errorMessage: string) {
        return { valid: false, errorMessage };
      }

    function checkValidAssignmentDateRange(dateRange: DateRangePickerProps.Value | null) {
        if (dateRange == null || dateRange.type === 'relative') {
            return invalidDate("Invalid dates: select a beginning and end date");
        }
        if (!startDate || !endDate) {
          return invalidDate('Invalid dates: Engagement dates are not defined');
        }
        if (compareAsc(parse(`${endDate}T23:59:59`, "yyyy-MM-dd'T'HH:mm:ss", refDate), parse(dateRange.endDate, "yyyy-MM-dd'T'HH:mm:ss", refDate)) < 0) {
          return invalidDate('Invalid dates: end of assignment must be before end of engagement');
        }
        if (compareAsc(parse(startDate, "yyyy-MM-dd", refDate), parse(dateRange.startDate, "yyyy-MM-dd'T'HH:mm:ss", refDate)) > 0) {
          return invalidDate('Invalid dates: begin of assignment must be after start of engagement');
        }
        return { valid: true, errorMessage: '' }
      }

      function showCapacityIssues(capacityIssue: string[]) {
        if (capacityIssue && capacityIssue.length > 0) {
          let message = `The ${(assignment?.__typename === 'UserAssignment' ? 'person' : 'team')} capacity may be insufficient at these dates: ${capacityIssue.join(", ")}`;
          if (capacityIssue.length > 8) {
            message = `The ${(assignment?.__typename === 'UserAssignment' ? 'person' : 'team')} capacity may be insufficient at these dates: ${capacityIssue.slice(0, 4).join(", ")} ... ${capacityIssue.slice(-4).join(", ")}`;
          }
          setAssignmentWarning([{
            header: `Potential capacity issue`,
            type: "warning",
            content: message,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setAssignmentWarning([]),
            id: `warn_assignment_${counter}`
          }]);
        } else setAssignmentWarning([]);
      }

    return (
        <Container
            header={
              <Header
                  variant="h3"
                  actions={
                      <Button iconName='close' onClick={() => {onCancel(assignment?.id!)}}/>
                  }
              >
                Assignment #{counter+1}
              </Header>}
            >
            <SpaceBetween direction='vertical' size='l'>
                <Flashbar items={assignmentWarning} />

                <TeamUserInput
                    teams={teams}
                    counter={counter}
                    selectedTeam={assignment?.__typename === 'UserAssignment' ? assignment?.user?.team : (assignment as TeamAssignment)?.team }
                    selectedUser={assignment?.__typename === 'UserAssignment' ? assignment?.user : undefined}
                    assignmentType={assignment?.__typename || (confidence === 100 ? 'UserAssignment' : 'TeamAssignment')}
                    onTeamChange={async (t) => {
                      const capacityIssue = await onTeamChanged(t);
                      showCapacityIssues(capacityIssue);
                    }}
                    onUserChange={async (teamId, teamName, userId, userName) => {
                      const capacityIssue = await onUserChanged(teamId, teamName, userId, userName);
                      showCapacityIssues(capacityIssue);
                    }}
                />

                <SpaceBetween direction='horizontal' size='l'>
                    <FormField
                        label={<div className="formfield">Dates <pre className="mandatory">*</pre></div>}>
                        <SpaceBetween direction='horizontal' size='xs'>
                        <DateRangePickerAndCheck
                            dates={{startDate: assignment?.startDate!, endDate: assignment?.endDate!, type: 'absolute'}}
                            onChange={async (dates) => {
                              const capacityIssue = await onDatesChanged(dates);
                              showCapacityIssues(capacityIssue);
                            }}
                            checkDate={ (d) => checkValidAssignmentDateRange(d)}
                        />
                        </SpaceBetween>
                    </FormField>
                    <FormField
                        label={<div className="formfield">FTE <pre className="mandatory">*</pre></div>}
                        description={"Full-Time Equivalent"}
                        constraintText={assignment?.__typename === 'UserAssignment' ? '0 < fte <= 1' : '0 < fte <= n' }
                        >
                        <LoadInput
                            counter={counter}
                            initialLoad={assignment?.load ? assignment.load.toString() : ''}
                            onChange={async (newInput) => {
                              const capacityIssue = await onLoadChanged(parseFloat(newInput));
                              showCapacityIssues(capacityIssue);
                            }}
                        />
                    </FormField>
                </SpaceBetween>

            </SpaceBetween>
            </Container>
        )
};

type TeamUserInputProps = {
    assignmentType: 'TeamAssignment' | 'UserAssignment';
    teams: Team[];
    counter?: number;
    selectedTeam?: Team;
    selectedUser?: User;
    onTeamChange: (team: Team | undefined) => void;
    onUserChange: (teamId: string, teamName: string, userId: string, userName:string) => void;
  };

const TeamUserInput = (props: TeamUserInputProps) => {
    const {onTeamChange, onUserChange, counter} = props;

    const [selectedTeamOption, setSelectedTeamOption] = useState<SelectProps.Option | null>(null);
    const [selectedUserOption, setSelectedUserOption] = useState<SelectProps.Option | null>(null);
    const [userOptions, setUserOptions] = useState<SelectProps.Option[]>([]);
    const [teamOptions, setTeamOptions] = useState<SelectProps.Option[]>([]);

    useEffect(() => {
        (async () => {
          const teams = props.teams;
          if (teams && teams.length > 0) {
            const teamOptions = teams.map(({ teamId, name }) => ({ label: name || teamId, value: teamId }));
            setTeamOptions(teamOptions);
          }
        })();
    }, [props.teams]);

    useEffect(() => {
        (async () => {
          if (props.selectedTeam) {
            if (props.selectedTeam.teamId !== selectedTeamOption?.value) {
              setSelectedTeamOption({value: props.selectedTeam.teamId, label: props.selectedTeam.name});
            }
          }
        })();
    }, [props.selectedTeam]);

    useEffect(() => {
      (async () => {
        if (selectedTeamOption) {
          if (props.assignmentType === 'UserAssignment') {
            const team = props.teams.find(t => t.teamId === selectedTeamOption.value);
            const userOptions = team?.users?.map(({ userId, name }) => ({ label: `${name} (${userId})`, value: userId })) || [];
            setUserOptions(userOptions);

            if (props.selectedUser) {
              setSelectedUserOption({value: props.selectedUser.userId, label: props.selectedUser.name});
            }
          } else {
            let team = props.teams.find((t) => (t.teamId === selectedTeamOption.value!));
            if (team) {
              onTeamChange(team);
            }
          }
        }
      })();
    }, [selectedTeamOption, props.assignmentType]);

    useEffect(() => {
      selectedUserOption && onUserChange(selectedTeamOption?.value!, selectedTeamOption?.label!, selectedUserOption.value!, selectedUserOption.label!);
    }, [selectedUserOption]);

    return (
      <SpaceBetween direction='horizontal' size='l'>
        <div style={{ minWidth: 200 }}>
          <FormField label={<div className="formfield">Team <pre className="mandatory">*</pre></div>}>
            <Select data-testid={`team-${counter}`} selectedOption={selectedTeamOption} onChange={(e) => setSelectedTeamOption(e.detail.selectedOption)} options={teamOptions} />
          </FormField>
        </div>
         { props.assignmentType === 'UserAssignment' && (
            <div style={{ minWidth: 200 }}>
            <FormField label={<div className="formfield">Member <pre className="mandatory">*</pre></div>}>
                <Select data-testid={`member-${counter}`}  selectedOption={selectedUserOption} onChange={(e) => setSelectedUserOption(e.detail.selectedOption)} options={userOptions} />
            </FormField>
            </div>
        )}
      </SpaceBetween>
    );
  };

const LoadInput = (props: {readOnly?: boolean; initialLoad?: string; onChange: (newInput: string) => void; counter?: number }) => {
    const { initialLoad, onChange, counter} = props;
    const [input, setInput] = useState('');

    useEffect(() => {
      initialLoad && setInput(initialLoad);
    }, [initialLoad]);

    return (
      <Input
        data-testid={`load-${counter}`}
        placeholder='0.5'
        inputMode='numeric'
        value={input}
        onChange={(e) => { if (e.detail.value.length < 5) {
          setInput(e.detail.value);
          onChange(e.detail.value);
        }}}
      />
    );
  };

  export default AssignmentForm;