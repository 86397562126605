import { combineReducers } from "redux";
import { filterReducer } from "./filter";

export interface Action {
  type: string;
}

export const rootReducer = combineReducers({
  filterState: filterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
