import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Box, Container, Flashbar, Header} from "@cloudscape-design/components";
import ProjectForm from "../forms/ProjectForm";
import {API, Auth} from "aws-amplify";
import {CreateProjectInput, Project, UpdateProjectInput} from "../api-types";

interface ProjectParam {
  projectId: string;
  source?: string;
}

const EditProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const projectParam = location.state as ProjectParam;
  const [project, setProject] = useState<Project>();
  const [title, setTitle] = useState<string>();
  const [error, setError] = useState<any>([]);

  useEffect(() => {
    (async () => {
      if (!projectParam) {
        setTitle("Create Engagement");
        return;
      }
      setTitle("Edit Engagement");
      const projectId = projectParam.projectId;
      const { data } = await API.graphql<any>({
        query: `{ getProject(projectId: "${projectId}") {
          projectId, name, projectType
          ... on Project {
            startDate endDate confidence region geo managerId engagementId projectCategory
            assignments {
              ... on TeamAssignment { __typename id
                endDate startDate load team { teamId name }
              }
              ... on UserAssignment { __typename id
                endDate startDate load user { userId name team { teamId name } }
              }
            }
          }
        }}`,
      });
      const project: Project = data.getProject;
      setProject(project);
    })();
  }, [projectParam]);

  async function submit(updatedProject: Project) {
    setError([]);
    const session = await Auth.currentSession();
    if (project) {
      updatedProject.assignments?.forEach((assignment) => {
        if (assignment.__typename === "TeamAssignment") {
          delete assignment.team?.users; // we don't want the full list of users in the backend
        }
      });
      const updatedProjectInput: UpdateProjectInput = {
        data: JSON.stringify(updatedProject)
      }
      API.graphql<any>({
        query: `mutation ($project: UpdateProjectInput!) { changeProject(project: $project) { projectId } }`,
        variables: { project: updatedProjectInput },
      }, {
        Authorization: session.getIdToken().getJwtToken() // https://github.com/aws-amplify/amplify-js/issues/1772#issuecomment-426556384
      })
        .then(() => navigate(projectParam?.source?projectParam?.source:"/engagements"))
        .catch((err) => {
          console.error(err);
          setError([{
            header: "Failed to update project",
            type: "error",
            content: err.errors[0].message,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setError([]),
            id: "error_creation_1"
          }]);
        });
    } else {
      const createProjectInput: CreateProjectInput = {
        confidence: updatedProject.confidence!,
        managerId: updatedProject.managerId,
        engagementId: updatedProject.engagementId || '',
        projectType: updatedProject.projectType!,
        projectCategory:  updatedProject.projectCategory!,
        region: updatedProject.region!,
        name: updatedProject.name!,
        startDate: updatedProject.startDate!,
        endDate: updatedProject.endDate!,
        assignments: updatedProject.assignments?.map((a) => {
          let assignment: any = {
            type: a.__typename,
            load: a.load,
            startDate: a.startDate,
            endDate: a.endDate,
          };
          if (a.__typename === "TeamAssignment") {
            assignment["teamId"] = a.team?.teamId;
          } else if (a.__typename === "UserAssignment") {
            assignment["userId"] = a.user?.userId;
          } else {
            console.warn("Unknown assignment type");
          }
          return assignment;
        }),
      };
      API.graphql<any>({
        query: `mutation ($project: CreateProjectInput!) { createProject(project: $project) }`,
        variables: { project: createProjectInput },
      }, {
        Authorization: session.getIdToken().getJwtToken() // https://github.com/aws-amplify/amplify-js/issues/1772#issuecomment-426556384
      })
        .then(() => navigate(projectParam?.source?projectParam?.source:"/engagements"))
        .catch((err) => {
          console.error(err);
          setError([{
            header: "Failed to create project",
            type: "error",
            content: err.errors[0].message,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setError([]),
            id: "error_update_1"
          }]);
        });
    }
  }

  return (
    <Box padding="xl">
      <div style={{ maxWidth: 1000, margin: "auto" }}>
        <Container header={<Header variant="h2">{title}</Header>}>
          <ProjectForm
            initialState={project}
            onCancel={() => {
              navigate(projectParam?.source?projectParam?.source:"/engagements");
            }}
            onSubmit={(updatedProject) => {
              submit(updatedProject);
            }}
          />
          <br />
          <Flashbar items={error} />
        </Container>
      </div>
    </Box>
  );
};

export default EditProject;
