import React from "react";
import {DateRangePicker, DateRangePickerProps,} from "@cloudscape-design/components";
import {compareAsc, parse} from "date-fns";
import {datePickerAriaLabels} from "../aria-label";

type DateRangePickerAndCheckProps = {
  dates?: DateRangePickerProps.Value;
  readOnly?: boolean;
  onChange: (date: DateRangePickerProps.AbsoluteValue) => void;
  checkDate?: (date: DateRangePickerProps.AbsoluteValue) => {valid: boolean, errorMessage: string};
};

const DateRangePickerAndCheck = (props: DateRangePickerAndCheckProps) => {
  const { dates, readOnly, onChange, checkDate } = props;
  const refDate = new Date();

  function invalidDate(errorMessage: string) {
    return { valid: false, errorMessage };
  }

  function checkValidDateRange(dateRange: DateRangePickerProps.Value | null) {
    if (dateRange == null || dateRange.type === "relative") {
        return invalidDate("Invalid dates: select a beginning and end date");
    }

    let start = dateRange.startDate.split("T");
    if (!start[0]) {
      return invalidDate("Invalid dates: select a start date");
    }

    let end = dateRange.endDate.split("T");
    if (!end[0]) {
      return invalidDate("Invalid dates: select a end date");
    }

    if (
      compareAsc(
        parse(end[0], "yyyy-MM-dd", refDate),
        parse(start[0], "yyyy-MM-dd", refDate)
      ) < 0
    ) {
      return invalidDate("Invalid dates: end must be after start");
    }

    if (checkDate) {
        return checkDate(dateRange);
    }

    return { valid: true, errorMessage: "" };
  }

  return (
    <DateRangePicker
      data-testid="projectDates"
      value={dates || { startDate: "", endDate: "", type: "absolute" }}
      onChange={(e) => {
        if (
          e.detail != null &&
          e.detail.value != null &&
          e.detail.value.type === "absolute"
        ) {
          onChange(e.detail.value);
        } else {
          console.warn("Date Range Picker issue");
        }
      }}
      dateOnly={true}
      isValidRange={(v) => checkValidDateRange(v)}
      placeholder="Start and end dates"
      rangeSelectorMode="absolute-only"
      showClearButton={false}
      relativeOptions={[]}
      disabled={readOnly}
      i18nStrings={datePickerAriaLabels}
    />
  );
};

export default DateRangePickerAndCheck;
