import React, {useState} from 'react';
import Chart, {ChartProps, initialState, TimeScale} from './Chart';
import Decimal from 'decimal.js';

export type TeamUserChartProps = ChartProps & {
  showCapacity?: boolean;
};

const TeamUserChart = (props: TeamUserChartProps) => {
  const { data, showCapacity = false } = props;
  const [timeScale, setTimeScale] = useState(initialState.timeScale);

  let headers: ChartHeaders = [];
  if (showCapacity) {
    let teams: any = data.filter((row: any) => row.loadAndCapacity);
    teams = teams.map((team: any) => ({
      ...team,
      loadAndCapacity: {
        ...(Object.entries<number>(team.loadAndCapacity)
          .filter(([key]: any) => key !== 'capacity')
          .reduce((acc: any, [key, value], i, loads) => {
            let idx;
            switch (timeScale) {
              case TimeScale.Months:
                const firstYear = new Date(loads[0][0]).getFullYear();
                const currentYear = new Date(key).getFullYear();
                const yearDiff = currentYear - firstYear;
                const firstMonth = new Date(loads[0][0]).getMonth();
                const currentMonth = new Date(key).getMonth();
                idx = currentMonth + yearDiff * 12 - firstMonth;
                break;
              case TimeScale.Weeks:
                idx = Math.floor(i / 7);
                break;
              default:
                idx = i;
                break;
            }
            acc[idx] = Math.max(acc[idx] || -Infinity, value);
            return acc;
          }, {}) as any),
        capacity: team.loadAndCapacity.capacity,
      },
    }));

    headers = teamChartHeadersAdaptor(teams);
  }

  return <Chart headers={headers} onTimeScaleChanged={(newTimeScale) => setTimeScale(newTimeScale)} {...props} />;
};

type ChartHeaders = TeamUserChartProps['headers'];

function teamChartHeadersAdaptor(teams: any[]): ChartHeaders {
  return teams.map((team) => ({
    id: `TEAM#${team.id}`,
    content: <div style={styles.header()}>Capacity</div>,
    cells: Object.entries(team.loadAndCapacity)
      .filter(([key]: any) => key !== 'capacity')
      .map(([date, load]: any) => ({
        id: `${team.id}-header-cell-${date}`,
        content: <div style={styles.header(team.loadAndCapacity.capacity - load)}>{Decimal.sub(team.loadAndCapacity.capacity, load).toString()}</div>,
      })),
  }));
}

const styles = {
  header: (availability?: number) => ({
    backgroundColor: availability === undefined ? '#687078' : availability > 0 ? '#1d8102' : availability < 0 ? '#d13212' : '#687078',
    color: 'white',
    textAlign: 'center' as any,
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  }),
};

export default TeamUserChart;
