import {Amplify} from 'aws-amplify';

const redirectUrl = window.location.hostname === 'localhost' ? 'https://localhost:3000' : `https://${window.location.hostname}`;

const setupAmplify = async () =>
  fetch('/config.json')
    .then((data) => data.json())
    .then(async (config) => {
      const amplifyConfig = {
        Auth: {
          mandatorySignIn: true,
          region: config.REGION,
          userPoolId: config.USER_POOL_ID,
          userPoolWebClientId: config.USER_POOL_APP_ID,
          cookieStorage: {
            sameSite: 'lax',
            secure: true
          },
          storage: window.sessionStorage,
          oauth: {
            domain: config.USER_POOL_DOMAIN,
            scope: ['openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: redirectUrl,
            redirectSignOut: redirectUrl,
            responseType: 'code',
            options: {
              AdvancedSecurityDataCollectionFlag: false,
            },
          },
        },
        API: {
          aws_project_region: config.REGION,
          aws_appsync_graphqlEndpoint: config.APPSYNC_ENDPOINT,
          aws_appsync_region: config.REGION,
          aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        },
      };
      await Amplify.configure(amplifyConfig);
      return amplifyConfig;
    });

export default setupAmplify;
