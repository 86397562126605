import React, { useContext, useEffect, useState } from 'react';
import {API, Auth} from 'aws-amplify';
import {
  Box,
  Button,
  Flashbar,
  Form,
  FormField,
  Input,
  Multiselect,
  Select,
  SelectProps,
  SpaceBetween,
} from '@cloudscape-design/components';
import {CreateTeamInput, Team, User} from '../api-types';
import {OptionDefinition} from '@cloudscape-design/components/internal/components/option/interfaces';
import UserContext from "../contexts/UserContext";

type TeamFormProps = {
  teamId?: string;
  onCancel: () => void;
  onSubmit?: (data: Team) => void;
};

const TeamForm = (props: TeamFormProps) => {
  const user = useContext(UserContext);
  const { onCancel, onSubmit, teamId } = props;
  const [state, setState] = useState<Team>({});

  const [userOptions, setUserOptions] = useState<SelectProps.Option[]>([]);
  const [managerOptions, setManagerOptions] = useState<SelectProps.Option[]>([]);
  const [loading, showLoading] = useState<boolean>(false);
  const [teamNameError, showTeamNameError] = useState<boolean>(false);
  const [managerError, showManagerError] = useState<boolean>(false);
  const [error, setError] = useState<any>([]);

  useEffect(() => {
    (async () => {
        if (teamId) {
        const { data } = await API.graphql<any>({
          query: `{ getTeam(teamId: "${teamId}") {
            teamId
            name
            managerId
            users {
              userId
              name
            }
          }}`,
        });
        setState(data.getTeam);
      }
    })();
}, [teamId]);


  async function submit(team: Team) {
    const session = await Auth.currentSession();
    showTeamNameError(false);
    showManagerError(false);
    let errors: boolean = false;

    if (!team.name) {
      showTeamNameError(true);
      errors = true;
    }
    if (!team.managerId) {
      showManagerError(true);
      errors = true;
    }
    if (errors) {
      return;
    }

    setError([]);
    showLoading(true);
    const input : CreateTeamInput = {
      managerId: team.managerId!,
      name: team.name!,
      users: team.users?.map((u) => ({userId: u.userId!})) || []
    };
    API.graphql<any>({
      query: `mutation ($team: CreateTeamInput!) { createTeam(team: $team) { teamId } }`,
      variables: { team : input },
    }, {
      Authorization: session.getIdToken().getJwtToken() // https://github.com/aws-amplify/amplify-js/issues/1772#issuecomment-426556384
    }).then(() => {
      onSubmit && onSubmit(team);
      showLoading(false);
    }).catch((err) => {
      console.error(err);
      showLoading(false);
      setError([{
            header: "Failed to create team",
            type: "error",
            content: err.errors[0].message,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setError([]),
            id: "error_create_team"
          }]);
    });
  }

  useEffect(() => {
    (async () => {
      const session = await Auth.currentSession();
      const { data } = await API.graphql<any>({ query: `{ getTeam(teamId: "aws") { users { userId name isManager geo } } }` }, {
        Authorization: session.getIdToken().getJwtToken() // https://github.com/aws-amplify/amplify-js/issues/1772#issuecomment-426556384
      });
      const team: Team = data.getTeam || [];
      const allAwsUsers = team.users?.filter((u) => u.geo === user.geo).sort((a, b) => a.name!.localeCompare(b.name!));

      const [userOptions, managerOptions] : [OptionDefinition[], OptionDefinition[]] = allAwsUsers?.reduce((result: [OptionDefinition[], OptionDefinition[]], element : User) => {
        result[element.isManager ? 1:0].push({ label: `${element.name} (${element.userId})`, value: element.userId });
        return result;
      }, [[], []]) || [[], []];
      setUserOptions(userOptions);
      setManagerOptions(managerOptions);
    })();
  }, []);

  return (
    <Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button formAction='none' variant='normal' onClick={onCancel}>
                Cancel
              </Button>
                <Button loading={loading} disabled={loading} formAction='none' variant='primary' onClick={() => submit(state)}>
                  Submit
                </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction='vertical' size='l'>

            <FormField label={<div className="formfield">Team name <pre className="mandatory">*</pre></div>} errorText={teamNameError && 'Team name is mandatory'}>
              <Input value={state.name || ''} onChange={ (e) => { if (e.detail.value.length < 100) setState({ ...state, name: e.detail.value }) } } />
            </FormField>

            <FormField label={<div className="formfield">Manager <pre className="mandatory">*</pre></div>} errorText={managerError && 'Manager is mandatory'}>
              <Select
                options={managerOptions}
                filteringType="auto"
                selectedOption={{value:state.managerId!}}
                onChange={ ({ detail }) => setState({ ...state, managerId: detail.selectedOption.value})}
              />
            </FormField>

            <FormField label='Members'>
              <Multiselect
                options={userOptions}
                filteringType="auto"
                selectedOptions={state.users?.map(({ userId }: any) => optionise(userId!)) || []}
                onChange={({ detail }) => setState({ ...state, users: detail.selectedOptions.map((option) => ({userId: option.value})) })}
              />
            </FormField>

            <Flashbar items={error} />
          </SpaceBetween>
        </Form>
      </form>
    </Box>
  );
};

const optionise = (id: string,) => ({ value : id, label: id });

export default TeamForm;
