import React, {useEffect, useState} from 'react';
import "@cloudscape-design/global-styles/index.css"
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {AppLayout, HelpPanel, Icon, Spinner} from '@cloudscape-design/components';
import {applyTheme, Theme} from '@cloudscape-design/components/theming';
import setupAmplify from './setupAmplify';
import UserContext from './contexts/UserContext';
import NavBar from './components/Navbar';
import Router from './Router';
import Navigation from './components/Navigation';
import { rootReducer } from './store/root';
import { legacy_createStore as createStore} from 'redux';
import { Provider as ReduxStoreProvider } from 'react-redux';

const store = createStore(rootReducer);

function App() {
  const [user, setUser] = useState();

  const theme: Theme = {
    tokens: {
      fontFamilyBase: "'Amazon Ember','Helvetica Neue',Roboto,Arial,sans-serif",
      colorBackgroundButtonPrimaryDefault: "#ec7211",
      colorBackgroundButtonPrimaryHover: "#eb5f07",
      colorBackgroundHomeHeader: "#232f3e",
      colorTextAccent: "#ec7211"
    }
  }

  const login = async () => {
    try {
      await setupAmplify();
    } catch (error) {
      console.error(`Failed to load config: ${error}`);
      throw error;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      user.isEm = JSON.parse(user?.signInUserSession.idToken.payload["custom:isEm"]); // parse boolean
      user.isManager = JSON.parse(user?.signInUserSession.idToken.payload["custom:is_manager"]); // parse boolean
      user.geo = user?.signInUserSession.idToken.payload["custom:geo"];
      setUser(user);
    } catch (error) {
      console.error(`Failed to get current user: ${error}`);
      await Auth.federatedSignIn({customProvider: 'AmazonFederate'});
    }
  };

  useEffect(() => {
    (async () => {
      applyTheme({ theme });
      await login();
    })();
  }, []);

  return user ? (
    <ReduxStoreProvider store={store}>
      <UserContext.Provider value={user}>
        <BrowserRouter>
          <NavBar />
          <AppLayout
            navigation={<Navigation />}
            disableBodyScroll={true}
            disableContentPaddings={true}
            // contentHeader={<NavBar />}
            headerSelector='#navbar'
            // footerSelector='#footer'
            content={<Router />}
            tools={
              <HelpPanel
                footer={
                  <div>
                    <h3>
                      Contact us <Icon name='external' />
                    </h3>
                    <ul>
                      <li>
                        <Icon name='envelope' /> <a href='mailto:emea-sa-specialists-builders@amazon.com?subject=Pipeline Tracker Tool'>Email</a>
                      </li>
                    </ul>
                  </div>
                }
                header={<h2>Help</h2>}
              >
                <div>
                  <h3>Engagement states</h3>
                  Engagements can be in two states:
                    <ul>
                      <li><b>In the pipeline</b>: when the <i>confidence</i> to do the project is not yet at 100%.</li>
                      <li><b>Committed</b>: when the <i>confidence</i> to do the project reaches 100%.</li>
                    </ul>
                  <h3>Colors &amp; Opacity</h3>
                  According to the state of the engagement, the color is different:
                  <ul>
                    <li>
                      Committed engagements are displayed in <div className='committed'>blue</div>.
                    </li>
                    <li>
                      Engagements in the pipeline are displayed in <div className='pipelined'>orange</div>.
                    </li>
                    <li>
                      Engagements with no assignment (team or individual) are displayed in <div className='unassigned'>black</div>.
                    </li>
                  </ul>
                  <p>Opacity depends on the confidence of the project. The less confident, the less opaque.</p>
                  <h3>Over capacity</h3>
                  When a team or a resource is over capacity, the corresponding time slot is marked as <div className='overloaded'>overloaded</div>.
                </div>
              </HelpPanel>
            }
          />
        </BrowserRouter>
      </UserContext.Provider>
    </ReduxStoreProvider>
  ) : (
    <div style={{ top:'50%', left: '50%', transform: 'translate3d(-50%,-50%, 0)', position: 'absolute'}}>
        <Spinner /> Authentication in progress...
    </div>
  );
}

export default App;
