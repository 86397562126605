import React, {useEffect, useState} from "react";

import {
    Box,
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Flashbar,
    FormField,
    Grid,
    Modal,
    Pagination,
    SpaceBetween,
    Table,
    Toggle,
} from "@cloudscape-design/components";
import TimeOffForm from '../forms/TimeOffForm';
import {API, Auth} from 'aws-amplify';
import {DeleteUserAssignmentInput, UserAssignment} from '../api-types';
import {isAfter, parse} from 'date-fns';


type ProfileActivitiesTabProps = {
    userID: string;
  };

const ProfileActivitiesTab = (props: ProfileActivitiesTabProps) => {
    const { userID } = props;
    const today = new Date();
    const [newTimeOffForm, showNewTimeOffForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any[]>([]);
    const [activities, setActivities] = useState<UserAssignment[]>([]);
    const [displayedActivities, setDisplayedActivities] = useState<UserAssignment[]>([]);
    const [refresh, doRefresh] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<UserAssignment[]>([]);
    const [currentPageIndex, setCurrentPageIndex ] = useState<number>(1);
    const [pagesCount, setPagesCount ] = useState<number>(1);
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: 10,
        custom: false
      });

    async function loadActivities() {
        setError([])
        setLoading(true);
        const session = await Auth.currentSession();
        const query = `{ getUserActivities (userId: "${userID}") { id startDate endDate load comment project { name projectId __typename } } }`;
        API.graphql<any>({
            query,
          }, {
            Authorization: session.getIdToken().getJwtToken() // https://github.com/aws-amplify/amplify-js/issues/1772#issuecomment-426556384
          })
          .then(({data}) => {
            setLoading(false);
            let activities = data.getUserActivities.filter((activity: UserAssignment) => (activity.project?.__typename==='OtherActivity')).sort((a: UserAssignment, b: UserAssignment) => -a.startDate!.localeCompare(b.startDate!));
            setActivities(activities);
            refreshTable(activities);
          })
          .catch((reason) => {
            setLoading(false);
            setActivities([]);
            setDisplayedActivities([]);
            setError([{
                header: "Failed to fetch activities",
                type: "error",
                content: 'Could not load activities, please try again later',
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setError([]),
                id: "error_load_activities_1"
              }]);
            console.error(reason);
          });
    }

    function refreshTable(activities: UserAssignment[]) {
        if (!preferences.custom) {
            activities = activities.filter((activity: UserAssignment) => isAfter(parse(activity.endDate!, 'yyyy-MM-dd', today), today));
        }
        setPagesCount(Math.ceil(activities.length / preferences.pageSize!));
        setDisplayedActivities(activities.slice((currentPageIndex-1) * preferences.pageSize!, (currentPageIndex) * preferences.pageSize!));
    }

      useEffect(() => {
        (async () => {
            if (refresh) {
                await loadActivities();
                doRefresh(false);
            }
        })();
      }, [refresh]);

      useEffect(() => {
        (async () => {
            setCurrentPageIndex(1);
            refreshTable(activities);
        })();
      }, [preferences]);

      useEffect(() => {
        (async () => {
            refreshTable(activities);
        })();
      }, [currentPageIndex]);

    function deleteSelectedActivities() {
        setError([]);
        const input: DeleteUserAssignmentInput[] = selectedItems.map((ua) => ({projectId: ua.project?.projectId!, assignmentId: ua.id!}));
        const query = `mutation ($input: [DeleteUserAssignmentInput]) { deleteUserAssignments (input: $input) }`;
        console.log(input);
        API.graphql<any>({
            query,
            variables: { input }
        })
        .then(() => {
            console.log("success");
            setSelectedItems([]);
            doRefresh(true);
        })
        .catch((reason) => {
            setError([{
                header: "Failed to delete activities",
                type: "error",
                content: 'Could not delete selected activities, please try again later',
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setError([]),
                id: "error_delete_activities_1"
              }]);
            console.error(reason);
        })
    }

    return (
        <div>
        <Flashbar items={error} />
        <br/>
        <Table
            loading={loading}
            onSelectionChange={({ detail }) =>
                setSelectedItems(detail.selectedItems)
            }
            selectedItems={selectedItems}
            ariaLabels={{
                selectionGroupLabel: "",
                allItemsSelectionLabel: ({ selectedItems }) =>
                `${selectedItems.length} ${
                    selectedItems.length === 1 ? "activity" : "activities"
                } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                const isItemSelected = selectedItems.filter(
                    i => i.id === item.id
                ).length;
                return `${item.project?.name} is ${
                    isItemSelected ? "" : "not"
                } selected`;
                }
            }}
            columnDefinitions={[
                {
                    id: "startDate",
                    header: "Start Date",
                    cell: e => e.startDate
                },
                {
                    id: "endDate",
                    header: "End Date",
                    cell: e => e.endDate
                },
                {
                    id: "description",
                    header: "Description",
                    cell: e => e.project?.name
                },
                {
                    id: "comment",
                    header: "Comment",
                    cell: e => e.comment
                }
            ]}
            items={displayedActivities}
            selectionType="multi"
            loadingText="Loading activities"
            trackBy="startDate"
            visibleColumns={[
                "startDate",
                "endDate",
                "description",
                "comment"
            ]}
            empty={
                error.length === 0 ?
                <Box textAlign="center" color="inherit">
                    <b>Nothing planned</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No activities to display.
                    </Box>
                    <Button onClick={() => {showNewTimeOffForm(true)}}>Add</Button>
                </Box>
                :
                <Box textAlign="center" color="inherit">
                    <Button onClick={() => {doRefresh(true)}}>Refresh</Button>
                </Box>
            }
            header={
                <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
                    <Box padding={{ left: 'l' }} variant='h2' color='inherit'>
                        Other activities
                    </Box>
                    <Box float='right' >
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button disabled={selectedItems.length===0} variant='normal' onClick={() => deleteSelectedActivities()}>Remove </Button>
                            <Button variant='primary' onClick={() => {showNewTimeOffForm(true)}}>Add </Button>
                        </SpaceBetween>
                    </Box>
                </Grid>
            }
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={pagesCount}
                    onChange={({detail}) => setCurrentPageIndex(detail.currentPageIndex)}
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                />
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    onConfirm={({ detail }) => {setPreferences(detail);}}
                    preferences={preferences}
                    pageSizePreference={{
                        title: "Select page size",
                        options: [
                            { value: 10, label: "10 events" },
                            { value: 20, label: "20 events" },
                            { value: 50, label: "50 events" },
                            { value: 100, label: "100 events" },
                            { value: 100000, label: "All events" }
                        ]
                    }}
                    customPreference={(customValue, setCustomValue) => (
                        <FormField label="Show past events">
                        <Toggle
                            onChange={({ detail }) => {setCustomValue(detail.checked)}}
                            checked={customValue}
                            >
                            {customValue ? 'Yes' : 'No'}
                            </Toggle>
                        </FormField>
                    )}
                />
            }
            />
            {newTimeOffForm && (
                    <Modal header='Add other activity' visible={true} onDismiss={( {detail}) => { if (detail.reason !== 'overlay') showNewTimeOffForm(false) }}>
                        <TimeOffForm userID={userID} onCancel={() => showNewTimeOffForm(false)} onSubmit={(timeOff: UserAssignment) => {showNewTimeOffForm(false); doRefresh(true); }}/>
                    </Modal>
                )}
        </div>
    )
}

export default ProfileActivitiesTab;