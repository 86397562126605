import React, {useContext, useEffect, useState} from 'react';
import { Container, ContentLayout, Header, Link, Tabs, } from "@cloudscape-design/components";

import UserContext from '../contexts/UserContext';
import ProfileActivitiesTab from './ProfileActivitiesTab';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {API} from 'aws-amplify';

const Profile = () => {
    const navigate = useNavigate();
    const user = useContext(UserContext);
    // console.log(user);
    const [title, setTitle] = useState<string>("");
    const [searchParams] = useSearchParams();
    let userID = searchParams.get("user") || '';

    useEffect(() => {
        (async () => {
            if (userID) {
                const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
                const { data } = await API.graphql<any>({ query: `{ getUser(userId: "${userID}") { name managerId } }` });
                const userData = data.getUser;
                if (userID === user?.attributes?.preferred_username || userData.managerId === user?.attributes?.preferred_username || groups.includes('admins') ) {
                    setTitle(`${userData.name} (${userID})`);
                } else {
                    console.error('You are not allowed to see this page');
                    navigate(-1);
                }
            } else {
                userID = user?.attributes?.preferred_username
                setTitle(`${user?.attributes?.given_name} ${user?.attributes?.family_name} (${userID})`)
            }
        })();
      }, []);

    return (
        <ContentLayout
      className="layout"
      header={
            <Header variant="h2">
                <div style={{display: "flex", alignItems:"center"}}>
                    <div>
                       <img className='profile_avatar' src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userID}`} alt={userID}/>
                    </div>
                    <div style={{paddingLeft: "1em"}}>
                      {title}
                      <br/>
                      <Link fontSize='body-s' external href={`https://phonetool.amazon.com/users/${userID}`}>phonetool</Link> | <Link fontSize='body-s' external href={`https://skills.amazon.com/users/${userID}`}>skills</Link>
                    </div>
                </div>
            </Header>}>
            <Container>
              <Tabs
                  tabs={[
                      {
                          label: "Engagement history",
                          id: "history",
                          content: "Not yet implemented",
                          disabled: true
                      },
                      {
                          label: "Other activities",
                          id: "off",
                          content: (
                              <ProfileActivitiesTab userID={userID} />
                          )
                      }
                  ]}
                  />
            </Container>
        </ContentLayout>
    )
}

export default Profile;