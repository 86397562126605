import { Action } from './root';
import { PropertyFilterProps } from "@cloudscape-design/components";

const SET_QUERY = 'SET_QUERY';
const RESET_QUERY = 'RESET_QUERY';

interface SetQueryAction extends Action {
  type: typeof SET_QUERY;
  query: PropertyFilterProps.Query;
}

interface ResetQueryAction extends Action {
  type: typeof RESET_QUERY;
}

type FilterAction = SetQueryAction | ResetQueryAction ;

export function setQuery(query: PropertyFilterProps.Query): SetQueryAction {
  return {
    type: 'SET_QUERY',
    query
  };
}

export function resetQuery(): ResetQueryAction {
  return {
    type: 'RESET_QUERY'
  };
}

interface FilterState {
  query: PropertyFilterProps.Query
}

const initialState: FilterState = {
  query: {
    tokens: [],
    operation: "and",
  }
};

export function filterReducer(state: FilterState = initialState, action: FilterAction) {
  switch (action.type) {
    case SET_QUERY:
      return { query: action.query };
    case RESET_QUERY:
      return initialState;
    default:
      return state;
  }
}
