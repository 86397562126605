import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {API, Auth} from 'aws-amplify';
import {Box, Button, Flashbar, Form, Header, Icon, Modal, SpaceBetween, Table} from '@cloudscape-design/components';
import {Project} from '../api-types';
import {ValueWithLabel} from './ValueWithLabel';
import UserContext from "../contexts/UserContext";

type ProjectDetailsProps = {
  source?: string;
  projectId: string;
  onDelete: () => void;
};

const ProjectDetails = (props: ProjectDetailsProps) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { onDelete, projectId, source } = props;
  const [project, setProject] = useState<Project>();
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [deleteEnabled, setDeleteEnabled] = useState<boolean>(false);
  const [deleteProjectModal, showDeleteProjectModal] = useState<boolean>(false);
  const [error, setError] = useState<any>([])
  const [managerName, setManagerName] = useState('')

  useEffect(() => {
    (async () => {
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

        try {
          const result = await API.graphql<any>({
            query: `{ getProject(projectId: "${projectId}") {
              projectId, name, projectType,
              ... on Project { startDate endDate confidence region createdBy managerId engagementId projectCategory
                assignments {
                  ... on TeamAssignment { __typename id
                    endDate startDate load team { teamId name }
                  }
                  ... on UserAssignment { __typename id
                    endDate startDate load user { userId name team { teamId name } }
                  }
                }
              }
            }}`,
          })
          setProject(result.data.getProject);

          if (result.data.getProject.managerId === user?.attributes?.preferred_username  // engagement manager
              || result.data.getProject.createdBy === user?.attributes?.preferred_username // creator of the project
              || groups.includes('admins')
          ) {
            setDeleteEnabled(true);
          }

          if (result.data.getProject.managerId) {
            const { data } = await API.graphql<any>({ query: `{ getUser(userId: "${result.data.getProject.managerId}") { name } }` });
            const userData = data.getUser;
            setManagerName(userData.name)
          }
        } catch (err) {
          console.error(err)
        }
    })();
}, [projectId]);


  async function deleteProject(project: Project) {
    if (!deleteEnabled) {
      setError([{
        header: "Forbidden",
        type: "error",
        content: 'You are not allowed to delete this project',
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setError([]),
        id: "error_delete_0"
      }]);
    } else {
      const session = await Auth.currentSession();
      setDeleteInProgress(true);
      setError([]);
      API.graphql<any>({
        query: `mutation ($projectId: ID!) { deleteProject(projectId: $projectId) }`,
        variables: {projectId: project.projectId},
      }, {
        Authorization: session.getIdToken().getJwtToken() // https://github.com/aws-amplify/amplify-js/issues/1772#issuecomment-426556384
      }).then(() => {
        setDeleteInProgress(false);
        showDeleteProjectModal(false);
        onDelete();
      })
          .catch((err) => {
            console.error(err);
            setDeleteInProgress(false);
            setError([{
              header: "Failed to delete engagement",
              type: "error",
              content: err.errors[0].message,
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => setError([]),
              id: "error_delete_1"
            }]);
          });
    }
  }

  function getProjectType() : string {
    switch (project?.projectType) {
      case "Customer":
        return "Customer Facing";
      case "Internal":
        return "Internal Project";
      default:
        return "";
    }
  }


  return (
    <Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              { deleteEnabled ?
                <Button formAction='none' iconName='status-negative' variant='normal' onClick={() => showDeleteProjectModal(true)}>
                  Delete engagement
                </Button> : <></>
              }
                <Button formAction='none' iconName='edit' variant='primary' onClick={() => navigate('/edit-engagement', {
                    state : {
                      projectId,
                      source
                    }
                  })} >
                  Edit
                </Button>
              </SpaceBetween>}
        >
          <SpaceBetween direction="vertical" size="s">
            <ValueWithLabel label="Engagement name" children={project?.name || '-'}/>

            <ValueWithLabel label="Engagement ID" children={project?.engagementId || '-'}/>


            <ValueWithLabel label="Region" children={project?.region || '-'}/>

            <ValueWithLabel label="Engagement Manager" children={managerName || '-'}/>

            <ValueWithLabel label="Engagement type" children={getProjectType()}/>

            <ValueWithLabel label="Engagement Category" children={project?.projectCategory || 'Other'}/>

            <ValueWithLabel label="Dates">
                <SpaceBetween direction="horizontal" size="xs">
                  {project?.startDate} <Icon name="angle-right"/> {project?.endDate}
                  </SpaceBetween>
            </ValueWithLabel>

            <ValueWithLabel label="Confidence">
              <div className="w3-light-grey" style={{ width: 220 }}>
                <div data-testid="confidence-value"  className="w3-container w3-blue w3-center" style={{ width: `${project?.confidence}%`}}>{project?.confidence}%</div>
              </div>
            </ValueWithLabel>

            <Table
                  data-testid="project-assignments"
                  ariaLabels={{
                    selectionGroupLabel: '',
                    allItemsSelectionLabel: () => '',
                    itemSelectionLabel: () => ''
                  }}
                  columnDefinitions={project?.confidence===100 ? [
                    {
                      id: "teamname",
                      header: "Team",
                      cell: e => e.teamname,
                      sortingField: "teamname"
                    },
                    {
                      id: "name",
                      header: "Member",
                      cell: e => e.username,
                      sortingField: "name"
                    },
                    {
                      id: "nickname",
                      header: "Nick",
                      cell: e => e.userId,
                      sortingField: "userId"
                    },
                    {
                      id: "dates",
                      header: "Dates",
                      cell: e => e.dates,
                      sortingField: "dates"
                    },
                    { id: "load", header: "Load", cell: e => e.load }
                  ] : [
                    {
                      id: "teamname",
                      header: "Team",
                      cell: e => e.teamname,
                      sortingField: "teamname"
                    },
                    {
                      id: "dates",
                      header: "Dates",
                      cell: e => e.dates,
                      sortingField: "dates"
                    },
                    { id: "load", header: "Load", cell: e => e.load }
                  ]}
                  items={
                    project?.assignments?.map((a) => {
                      if (a.__typename === 'UserAssignment') {
                        return {
                          teamname: a.user?.team?.name || '',
                          username: a.user?.name || '',
                          userId: a.user?.userId || '',
                          dates: (
                            <SpaceBetween direction="horizontal" size="xs">
                              {a.startDate} <Icon name="angle-right"/> {a.endDate}
                            </SpaceBetween>
                          ),
                          load: a.load!
                        }
                      } else if (a.__typename === 'TeamAssignment') {
                        return {
                          teamname: a.team?.name || '',
                          username: '',
                          userId: '',
                          dates: (
                            <SpaceBetween direction="horizontal" size="xs">
                              {a.startDate} <Icon name="angle-right"/> {a.endDate}
                            </SpaceBetween>
                          ),
                          load: a.load!
                        }
                      }
                      return {
                        dates:(<div></div>),
                        load: 0,
                        teamname: '',
                        userId: '',
                        username: ''
                      }
                    }) || []
                  }
                  loadingText="Loading resources"
                  trackBy="teamname"
                  visibleColumns={[
                    "teamname",
                    "name",
                    "nickname",
                    "dates",
                    "load"
                  ]}
                  empty={
                    <Box textAlign="center" color="inherit">
                      <b>No one</b>
                    </Box>
                  }
                  header={
                    <Header>
                      Assignments
                    </Header>
                  }
                />
          </SpaceBetween>
        </Form>
      </form>
      { deleteEnabled ?
        <Modal
          onDismiss={() => showDeleteProjectModal(false)}
          visible={deleteProjectModal}
          closeAriaLabel="Cancel"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={ () => showDeleteProjectModal(false) }>No</Button>
                <Button loading={deleteInProgress} disabled={deleteInProgress} variant="primary" onClick={ (e) => { e.preventDefault(); deleteProject(project!)} }>Yes</Button>
              </SpaceBetween>
            </Box>
          }
          header={`Delete engagement ${project?.name}?`}
        >
          Do you want to remove engagement "{project?.name}" and all its assignments?
              <Flashbar items={error} />
        </Modal> : <></>
      }
    </Box>
)
};

export default ProjectDetails;
