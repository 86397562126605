import {DateRangePickerProps} from "@cloudscape-design/components";

export const filteringAriaLabels={
    filteringAriaLabel: "your choice",
    dismissAriaLabel: "Dismiss",
    filteringPlaceholder: "Search",
    groupValuesText: "Values",
    groupPropertiesText: "Properties",
    operatorsText: "Operators",
    operationAndText: "and",
    operationOrText: "or",
    operatorLessText: "Less than",
    operatorLessOrEqualText: "Less than or equal",
    operatorGreaterText: "Greater than",
    operatorGreaterOrEqualText: "Greater than or equal",
    operatorContainsText: "Contains",
    operatorDoesNotContainText: "Does not contain",
    operatorEqualsText: "Equals",
    operatorDoesNotEqualText: "Does not equal",
    editTokenHeader: "Edit filter",
    propertyText: "Property",
    operatorText: "Operator",
    valueText: "Value",
    cancelActionText: "Cancel",
    applyActionText: "Apply",
    allPropertiesLabel: "All properties",
    tokenLimitShowMore: "Show more",
    tokenLimitShowFewer: "Show fewer",
    clearFiltersText: "Clear filters",
    removeTokenButtonAriaLabel: () => "Remove token",
    enteredTextLabel: (text:string) => `Use: "${text}"`
  }

export const datePickerAriaLabels = {
    todayAriaLabel: "Today",
    nextMonthAriaLabel: "Next month",
    previousMonthAriaLabel: "Previous month",
    customRelativeRangeDurationLabel: "Duration",
    customRelativeRangeDurationPlaceholder:
      "Enter duration",
    customRelativeRangeOptionLabel: "Custom range",
    customRelativeRangeOptionDescription:
      "Set a custom range in the past",
    customRelativeRangeUnitLabel: "Unit of time",
    formatRelativeRange: (e: DateRangePickerProps.RelativeValue) => {
      const t =
        1 === e.amount ? e.unit : `${e.unit}s`;
      return `Last ${e.amount} ${t}`;
    },
    formatUnit: (u: DateRangePickerProps.TimeUnit, n:number) => (1 === n ? u : `${u}s`),
    dateTimeConstraintText: "",
    relativeModeTitle: "Relative range",
    absoluteModeTitle: "Absolute range",
    relativeRangeSelectionHeading: "Choose a range",
    startDateLabel: "Start date",
    endDateLabel: "End date",
    startTimeLabel: "Start time",
    endTimeLabel: "End time",
    clearButtonLabel: "Clear and dismiss",
    cancelButtonLabel: "Cancel",
    applyButtonLabel: "Apply"
  }