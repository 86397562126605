import React from 'react';
import SideNavigation from '@cloudscape-design/components/side-navigation';
import {useLocation, useNavigate} from 'react-router-dom';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <SideNavigation
      activeHref={location.pathname}
      onFollow={(event) => {
        if(event.detail.external) return;
        event.preventDefault();
        navigate(event.detail.href);
      }}
      items={[
        { type: 'link', text: 'Engagements', href: '/engagements' },
        { type: 'link', text: 'Teams & Resources', href: '/teams' },
        { type: "divider" },
        { type: 'link', external: true, text: 'Feedback', href: 'https://survey.fieldsense.whs.amazon.dev/survey/74848793-d609-4e30-9068-1fb426800f10' },
      ]}
    />
  );
};

export default Navigation;
