import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../contexts/UserContext';
import {API, Auth} from 'aws-amplify';
import {
  Button,
  Container, ContentLayout,
  FormField,
  Header,
  Icon,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Tabs
} from '@cloudscape-design/components';
import {Team, User} from '../api-types';
import TeamUserChart, {TeamUserChartProps} from '../components/TeamUserChart';
import TeamForm from '../forms/TeamForm';
import {format} from 'date-fns';
import ProjectDetails from '../components/ProjectDetails';
import {OptionDefinition} from '@cloudscape-design/components/internal/components/option/interfaces';
import TeamDetails from '../components/TeamDetails';
import {ValueWithLabel} from '../components/ValueWithLabel';
import {useNavigate} from 'react-router-dom';

const allTeamsOption: OptionDefinition = { label: 'All teams', value: '_all_' };
const myTeamOption: OptionDefinition = { label: 'My team', value: '_mine_'};
const allConfidencesOption: OptionDefinition = {label:"All engagements (>= 0%)", value: "0"};

type TeamDetail = Team & {
  loadAndCapacity: any;
  datesFlagged?: Date[];
  loadFactorYTD?: number;
  loadFactorT12M?: number;
  users?: UserDetail[];
}

type UserDetail = User & {
    datesFlagged?: Date[];
    loadFactorYTD?: number;
    loadFactorT12M?: number;
}

const TeamUsers = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const confidences: OptionDefinition[] = [allConfidencesOption,{label:">= 25%", value: "25"}, {label:">= 50%", value: "50"}, {label:">= 75%", value: "75"}, {label:">= 90%", value: "90"}, {label:"100%", value: "100"}];

  const [teamOptions, setTeamOptions] = useState<SelectProps.Options>([]);

  const [selectedTeamOption, setSelectedTeamOption] = useState<OptionDefinition>(myTeamOption);
  const [selectedConfidenceOption, setSelectedConfidenceOption] = useState<OptionDefinition>(allConfidencesOption);

  const [rows, setRows] = useState<ChartData>([]);
  const [users, setUsers] = useState<UserDetail[]>([]);
  const [teams, setTeams] = useState<TeamDetail[]>([]);
  const [newTeamForm, showNewTeamForm] = useState(false);
  const [chosenTeamId, setChosenTeamId] = useState<string>();
  const [chosenProjectId, setChosenProjectId] = useState<string>();
  const [newTeam, setNewTeam] = useState<Team>();
  const [timeInterval, setTimeInterval] = useState<Interval>();
  const [refresh, doRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const session = await Auth.currentSession();
      const { data } = await API.graphql<any>({ query: `{ listTeams { teamId name } }` }, {
          Authorization: session.getIdToken().getJwtToken()
      });
      const teams: Team[] = data.listTeams?.sort((t1: Team, t2: Team) => t1.name!.localeCompare(t2.name!)).filter((t: Team) => t.teamId !== 'aws');

      let teamOptions = teams.map(({ teamId, name }) => ({ label: name || teamId, value: teamId }));
      setTeamOptions([{ label: "Quick access", options: [myTeamOption, allTeamsOption]}, { label: "Teams", options: teamOptions}]);
    })();
  }, [newTeam]);

  useEffect(() => {
    (async () => {
        setUsers([]);
        setTeams([]);
        setLoading(true);
        const session = await Auth.currentSession();
        let withUsers: boolean = false;
        let query: string;
        let dataToRetrieve = `teamId name 
                                users { 
                                    name userId isManager isEm managerId 
                                    assignments { 
                                        startDate endDate load 
                                        project { projectId name  __typename } 
                                    } 
                                } 
                                assignments { 
                                    endDate startDate load 
                                    project { projectId name confidence } 
                                } `;
        switch (selectedTeamOption.value) {
            case allTeamsOption.value:
                query = `{ 
                            listTeams { 
                                ${dataToRetrieve}
                            } 
                         }`;
                break;
            case myTeamOption.value:
                query = `{ 
                            getUser(userId: "${user.attributes.preferred_username}") { 
                                team { 
                                    ${dataToRetrieve}
                                }
                            }
                         }`;
                withUsers = true;
                break;
            default:
                query = `{ 
                            getTeam(teamId: "${selectedTeamOption.value}") { 
                                ${dataToRetrieve}
                            }
                        }`;
                withUsers = true;
        }

      API.graphql<any>({ query }, {
          Authorization: session.getIdToken().getJwtToken()
      }).then(async ({ data }) => {
        let teams: TeamDetail[];
        switch (selectedTeamOption.value) {
          case allTeamsOption.value:
            teams = data.listTeams?.filter((t: TeamDetail) => t.teamId !== 'aws').sort((a: TeamDetail, b: TeamDetail) => a.name!.localeCompare(b.name!));
            break;
          case myTeamOption.value:
            teams = [data.getUser.team].sort((a: TeamDetail, b: TeamDetail) => a.name!.localeCompare(b.name!));
            break;
          default:
            teams = [data.getTeam].sort((a: TeamDetail, b: TeamDetail) => a.name!.localeCompare(b.name!));
        }

        if (timeInterval?.start && timeInterval?.end) {
          const teamIds = "\"" + teams.map((t) => t.teamId!).join("\",\"") +"\"";
          query = `{
            getLoadAndCapacity(input: {
              begin: "${format(timeInterval.start, "yyyy-MM-dd")}",
              end: "${format(timeInterval.end, "yyyy-MM-dd")}",
              teamIds: [${teamIds}],
              withUsers: ${withUsers},
              confidenceThreshold: ${selectedConfidenceOption.value}
            })

            getLoadFactor(input: {
              teamIds: [${teamIds}],
              withUsers: ${withUsers}
            })
          }`;
          API.graphql<any>(
            { query },
            { Authorization: session.getIdToken().getJwtToken() } // https://github.com/aws-amplify/amplify-js/issues/1772#issuecomment-426556384
          ).then(({ data }) => {
            const loadAndCapacity = JSON.parse(data.getLoadAndCapacity);
            const loadFactor = JSON.parse(data.getLoadFactor);

            for (const team of teams) {
              team.datesFlagged = []
              if (loadFactor[team.teamId!] && loadFactor[team.teamId!]) {
                team.loadFactorT12M = loadFactor[team.teamId!].t12m;
                team.loadFactorYTD = loadFactor[team.teamId!].ytd;
              }
              const teamCapacity = loadAndCapacity[team.teamId!].capacity;
              if (loadAndCapacity[team.teamId!]) {
                team.loadAndCapacity = loadAndCapacity[team.teamId!];
                Object.keys(loadAndCapacity[team.teamId!]).map((key) => {
                    if (key !== 'capacity' && loadAndCapacity[team.teamId!][key] > teamCapacity) {
                        team.datesFlagged?.push(new Date(key));
                        return true;
                    }
                    return false;
                });
              }

              if (team.users && team.users.length > 0) {
                  let users: UserDetail[] = team.users.filter((u) => u.isManager === false && u.isEm === false).sort((a, b) => a.name!.localeCompare(b.name!))
                  for (const user of users) {
                      user.datesFlagged = [];
                      if (loadFactor[user.userId!] && loadFactor[user.userId!]) {
                          user.loadFactorT12M = loadFactor[user.userId!].t12m;
                          user.loadFactorYTD = loadFactor[user.userId!].ytd;
                      }
                      if (loadAndCapacity[user.userId!]) {
                          Object.keys(loadAndCapacity[user.userId!]).map((key) => {
                              if (loadAndCapacity[user.userId!][key] > 1) {
                                  user.datesFlagged?.push(new Date(key));
                                  return true;
                              }
                              return false;
                          });
                      }
                  }
                  team.users = users;
              }
            }
            setTeams(teams);
            const teamChartData = teamUsersChartDataAdaptor(teams, parseInt(selectedConfidenceOption.value!));
            setRows(teamChartData);
          });
        }
        else {
            const teamChartData = teamUsersChartDataAdaptor(teams, parseInt(selectedConfidenceOption.value!));
            setRows(teamChartData);
        }
        setUsers(teams.flatMap((t) => { return t.users || []; }));
        setLoading(false);
      }).catch((err) => {
        // TODO : show an error message
        setLoading(false);
        console.error(err);
      });
    })();
  }, [selectedTeamOption, selectedConfidenceOption, refresh, newTeam, timeInterval, user.attributes.preferred_username]);


  function selectTeamOrUser(id: string): void {
    const identifier = id.split("#")[1];
    if (id.startsWith("TEAM#"))
        setChosenTeamId(identifier);
    else if (id.startsWith("USER#")) {
      const userInDB = users.find((u) => u.userId === identifier);
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (identifier === user?.attributes?.preferred_username || userInDB?.managerId === user?.attributes?.preferred_username || groups.includes('admins')) {
        navigate(`/profile?user=${identifier}`);
      }
    }
  }

  function selectProjectOrOff(id: any) {
    if (id.startsWith("PROJECT#"))
      setChosenProjectId(id.split("#")[1])
  }

  return (
    <ContentLayout
      className="layout"
      header={
        <Header
          variant='h2'
          actions={
            <SpaceBetween direction='horizontal' size='xs'>

              <div style={{ minWidth: 200 }}>
              <FormField description="Team filter">
                <Select
                  filteringType="auto"
                  selectedOption={selectedTeamOption}
                  onChange={({ detail }: any) => setSelectedTeamOption(detail.selectedOption)}
                  options={teamOptions}
                  selectedAriaLabel='Selected'
                />
                </FormField>
              </div>
              <FormField description="&nbsp;">
                <Button iconName='add-plus' variant='primary' onClick={() => showNewTeamForm(true)}>
                    New Team
                </Button>
              </FormField>
            </SpaceBetween>
          }
        >
          {`${selectedTeamOption.label}`}
        </Header>
      }
    >
      <Container>
      <Tabs
          tabs={[
              {
                  label: "Engagements",
                  id: "engagements",
                  content:
                  (
                    <div>
                      <div style={{ maxWidth: 200, margin: '10px' }}>
                        <FormField description="Confidence threshold">
                        <Select
                          filteringType="auto"
                          selectedOption={selectedConfidenceOption}
                          onChange={({ detail }: any) => setSelectedConfidenceOption(detail.selectedOption)}
                          options={confidences}
                          selectedAriaLabel='Selected'
                        /></FormField>
                      </div>
                      <TeamUserChart data={rows} showCapacity={teams.length === 1} onRowClick={(id) => selectTeamOrUser(id)} onBarClick={(id) => selectProjectOrOff(id)} onDateChanged={(interval) => setTimeInterval(interval)} />
                  </div>
                  )
                },
                {
                  label: "Load",
                  id: "load",
                  content: (<div>
                      <SpaceBetween direction='vertical' size='s'>
                      {teams && teams.length > 0 ?
                      teams.map(team => {
                          let usersLoadFactor: JSX.Element[] = [];
                          if (team.users && team.users.length > 0) {
                              usersLoadFactor = team.users.filter((u: UserDetail) => u.loadFactorT12M !== undefined && u.loadFactorYTD !== undefined).map((user: UserDetail)=> {
                                  return (
                                      <ValueWithLabel label={user.name || ''} key={`load_factor_${user.userId}`}>
                                          <SpaceBetween direction='horizontal' size='s'>
                                              T12M: <div className="w3-light-grey" style={{ width: 300 }}>
                                              <div className="w3-container w3-blue w3-center" style={{ width: `${user.loadFactorT12M}%`}}>{user.loadFactorT12M! > 8 ? `${user.loadFactorT12M}%`:user.loadFactorT12M}</div>
                                          </div>
                                              YTD: <div className="w3-light-grey" style={{ width: 300 }}>
                                              <div className="w3-container w3-blue w3-center" style={{ width: `${user.loadFactorYTD}%`}}>{user.loadFactorYTD! > 8 ? `${user.loadFactorYTD}%`:user.loadFactorYTD}</div>
                                          </div>
                                          </SpaceBetween>
                                      </ValueWithLabel>
                                  );
                              });
                          }
                          return (
                              <div>
                                <ValueWithLabel label={team.name || ''} key={`load_factor_${team.teamId}`}>
                                  <SpaceBetween direction='horizontal' size='s'>
                                    T12M: <div className="w3-light-grey" style={{ width: 300 }}>
                                      <div className="w3-container w3-blue w3-center" style={{ width: `${team.loadFactorT12M}%`}}>{team.loadFactorT12M! > 8 ? `${team.loadFactorT12M}%`:team.loadFactorT12M}</div>
                                    </div>
                                    YTD: <div className="w3-light-grey" style={{ width: 300 }}>
                                      <div className="w3-container w3-blue w3-center" style={{ width: `${team.loadFactorYTD}%`}}>{team.loadFactorYTD! > 8 ? `${team.loadFactorYTD}%`:team.loadFactorYTD}</div>
                                    </div>
                                  </SpaceBetween>
                                </ValueWithLabel>
                                  <hr/>
                                {usersLoadFactor}
                              </div>
                          );
                      })
                    : loading ? <div><Spinner /> Loading data</div> : <div>No data</div>
                    }
                    </SpaceBetween>
                  </div>)
                }
              ]}
            />
      </Container>
      {newTeamForm && (
          <Modal header='Create New Team' visible={true} onDismiss={( {detail}) => { if (detail.reason !== 'overlay') showNewTeamForm(false) }}>
            <TeamForm
              onCancel={() => showNewTeamForm(false)}
              onSubmit={(team) => {
                showNewTeamForm(false);
                setNewTeam(team);
              }}
            />
          </Modal>
        )}
        {chosenTeamId && (
          <Modal header='Team Detail' visible={true} onDismiss={() => setChosenTeamId(undefined)}>
            <TeamDetails
              teamId={chosenTeamId}
              onCancel={() => setChosenTeamId(undefined)}
            />
          </Modal>
        )}
        {chosenProjectId && (
          <Modal
            header="Project Details"
            visible={true}
            size="large"
            onDismiss={() => setChosenProjectId(undefined)}
          >
            <ProjectDetails source="/teams" projectId={chosenProjectId} onDelete={ () => { setChosenProjectId(undefined); doRefresh(true)} } />
          </Modal>
        )}
    </ContentLayout>
  );
};

type ChartData = TeamUserChartProps['data'];

function teamUsersChartDataAdaptor(teams: TeamDetail[], confidence: number): ChartData {
    let chartData : ChartData = [];
    for (const team of teams) {
        chartData.push({
            id: `TEAM#${team.teamId!}`,
            label: (<><Icon name={"group"}/> <b>{team.name!}</b></>),
            loadAndCapacity: team.loadAndCapacity,
            bars:
                team.assignments?.filter((a) => a.project?.confidence! >= confidence).map((assignment) => ({
                    id: `PROJECT#${assignment.project?.projectId!}`,
                    label: assignment.project!.name!,
                    startDate: new Date(assignment.startDate!),
                    endDate: new Date(assignment.endDate!),
                    opacity: assignment.project?.confidence!,
                    color: assignment.project!.confidence === 100 ? '#336CAF' : '#EA7228',
                    meta: assignment.project!.confidence === 100 ? (
                        <div style={{ padding: 10 }}>
                            <b>load:</b> {assignment.load}
                            <br/>
                            <b>start date:</b> {assignment.startDate}
                            <br />
                            <b>end date:</b> {assignment.endDate}
                        </div>
                    ) : (<div style={{ padding: 10 }}>
                        <b>load:</b> {assignment.load}
                        <br/>
                        <b>confidence:</b> {assignment.project!.confidence}%
                        <br/>
                        <b>start date:</b> {assignment.startDate}
                        <br />
                        <b>end date:</b> {assignment.endDate}
                    </div>),
                })) || [],
            datesFlagged: team.datesFlagged,
            flagMeta: (<div style={{ padding: 10 }}><h4>Over Capacity</h4></div>)
        });
        if (team.users) {
            for (const user of team.users) {
                chartData.push({
                    id: `USER#${user.userId!}`,
                    label: user.name!,
                    bars:
                        user.assignments?.map((assignment) => ({
                            id: `${assignment.project!.__typename === 'Project'?'PROJECT#':'OFF#'}${assignment.project?.projectId!}`,
                            label: assignment.project!.name!,
                            startDate: new Date(assignment.startDate!),
                            endDate: new Date(assignment.endDate!),
                            opacity: 100,
                            color: assignment.project?.__typename === 'Project' ? '#336CAF' : '#C12806',
                            meta: (
                                <div style={{ padding: 10 }}>
                                    <b>load:</b> {assignment.load}
                                    <br/>
                                    <b>start date:</b> {assignment.startDate}
                                    <br />
                                    <b>end date:</b> {assignment.endDate}
                                </div>
                            ),
                        })) || [],
                    datesFlagged: user.datesFlagged,
                    flagMeta: (<div style={{ padding: 10 }}><h4>Over Capacity</h4></div>)
                });
            }
        }
    }
    return chartData;
}

export default TeamUsers;
