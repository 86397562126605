import React, {useContext} from 'react';
import {Box, Button, Grid, Icon, SpaceBetween} from '@cloudscape-design/components';
import * as awsui from '@cloudscape-design/design-tokens/index';
import UserContext from '../contexts/UserContext';
import {Auth} from 'aws-amplify';
import {useNavigate} from 'react-router-dom';

const NavBar = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div id="navbar" style={{ backgroundColor: awsui.colorBackgroundHomeHeader, color: awsui.colorTextFormSecondary }}>
      <Box padding="xxs">
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <Box padding={{ left: 'l' }} variant="h2" color="inherit">
            <img className="logo" src="logo192.png" alt={"Planning Pipeline Tool"} />
            <span style={{ color: awsui.colorTextHomeHeaderDefault }}>PACE Planning Tool</span>
            <span style={{ color: awsui.colorTextHomeHeaderDefault }}>&nbsp;-&nbsp;{user.geo}</span>
          </Box>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Box margin={{ top: 's' }} padding={{ right: 's' }} variant="h4" color="inherit">
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/profile?user=${user?.attributes?.preferred_username}`);
                  }}
                >
                  <span style={{ color: awsui.colorTextHomeHeaderDefault }}>
                    <Icon name="user-profile" />
                    &nbsp;&nbsp;{user?.attributes?.given_name} {user?.attributes?.family_name}
                  </span>
                </a>
              </Box>
              <Box margin={{ top: 's', right: 'm' }}>
                <Button variant="primary" onClick={() => Auth.signOut()}>
                  Sign Out
                </Button>
              </Box>
            </SpaceBetween>
          </Box>
        </Grid>
      </Box>
    </div>
  );
};

export default NavBar;
