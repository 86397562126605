import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {Badge, Box, Button, Form, SpaceBetween,} from '@cloudscape-design/components';
import {Team} from '../api-types';
import {ValueWithLabel} from './ValueWithLabel';

type TeamDetailsProps = {
  teamId?: string;
  onCancel: () => void;
};

const TeamDetails = (props: TeamDetailsProps) => {
  const { teamId, onCancel } = props;
  const [state, setState] = useState<Team>({});

  useEffect(() => {
    (async () => {
        if (teamId) {
        const { data } = await API.graphql<any>({
          query: `{ getTeam(teamId: "${teamId}") {
            teamId
            name
            managerId
            users {
              userId
              name
            }
          }}`,
        });
        setState(data.getTeam);
      }
    })();
}, [teamId]);

  return (
    <Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button formAction='none' variant='normal' onClick={onCancel}>
                Close
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction='vertical' size='l'>

              <ValueWithLabel label="Team name" children={state.name || ''}/>

              <ValueWithLabel label="Manager" children={
                state.users?.find((u) => {return u.userId === state.managerId})?.name || ''
              }/>

              <ValueWithLabel label="Members">
                <SpaceBetween direction='horizontal' size='xs' key='members'>
                  {
                    state.users?.filter((u) => {return u.userId !== state.managerId}).map(({ userId, name }: any) => (<Badge key={`user-${userId}`}>{`${name} (${userId})`}</Badge>)) || ''
                  }
                </SpaceBetween>
              </ValueWithLabel>

          </SpaceBetween>
        </Form>
      </form>
    </Box>
  );
};

export default TeamDetails;
