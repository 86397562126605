import React, { useEffect, useReducer } from 'react';
import { Box, Button, Container, SpaceBetween, Tabs } from '@cloudscape-design/components';
import GanttChart, { GanttChartProps, TimeScale } from './GanttChart';
import { startOfWeek } from 'date-fns';

export { TimeScale } from './GanttChart';

type Action = {
  type: string;
  payload?: any;
};

type State = {
  timeScale: TimeScale;
  timeUnits: number;
  initialDate: Date;
};

export const initialState: State = {
  timeScale: TimeScale.Weeks,
  timeUnits: 10,
  initialDate: startOfWeek(new Date()),
};

function reducer(state: State, action: Action): State {
  const { initialDate, timeScale, timeUnits } = state;

  switch (action.type) {
    case 'today':
      return { ...state, initialDate: new Date() };
    case 'zoomTo':
      return {
        ...state,
        initialDate: action.payload.initialDate,
        timeScale: action.payload.timeScale,
        timeUnits: action.payload.timeUnits,
      };
    case 'zoomIn':
      return { ...state, timeUnits: Math.max(timeUnits - 5, 5) };
    case 'zoomOut':
      return { ...state, timeUnits: Math.min(timeUnits + 5, 20) };
    case 'changeScale':
      let newInitialDate = initialDate;
      if (action.payload === TimeScale.Weeks) newInitialDate = startOfWeek(initialDate);
      if (action.payload === TimeScale.Months) newInitialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
      return { ...state, timeScale: action.payload, initialDate: newInitialDate };
    case 'nextDate':
      switch (timeScale) {
        case TimeScale.Months: {
          const nextMoth = initialDate.getMonth() + Math.floor(timeUnits / 3);
          const nextDate = new Date(initialDate.getFullYear(), nextMoth, initialDate.getDate());
          return { ...state, initialDate: nextDate };
        }
        case TimeScale.Weeks: {
          const nextDay = initialDate.getDate() + Math.floor(timeUnits / 3) * 7;
          const nextDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), nextDay);
          return { ...state, initialDate: nextDate };
        }
        case TimeScale.Days: {
          const nextDay = initialDate.getDate() + Math.floor(timeUnits / 3);
          const nextDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), nextDay);
          return { ...state, initialDate: nextDate };
        }
        default:
          return state;
      }
    case 'prevDate':
      switch (timeScale) {
        case TimeScale.Months: {
          const nextMoth = initialDate.getMonth() - Math.floor(timeUnits / 3);
          const nextDate = new Date(initialDate.getFullYear(), nextMoth, initialDate.getDate());
          return { ...state, initialDate: nextDate };
        }
        case TimeScale.Weeks: {
          const nextDay = initialDate.getDate() - Math.floor(timeUnits / 3) * 7;
          const nextDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), nextDay);
          return { ...state, initialDate: nextDate };
        }
        case TimeScale.Days: {
          const nextDay = initialDate.getDate() - Math.floor(timeUnits / 3);
          const nextDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), nextDay);
          return { ...state, initialDate: nextDate };
        }
        default:
          return state;
      }
    default:
      return state;
  }
}

const calcEndDate = (initialDate: Date, timeScale: TimeScale, timeUnits: number) => {
  switch (timeScale) {
    case TimeScale.Months: {
      const month = initialDate.getMonth() + timeUnits;
      return new Date(initialDate.getFullYear(), month, initialDate.getDate());
    }
    case TimeScale.Weeks: {
      const day = initialDate.getDate() + timeUnits * 7;
      return new Date(initialDate.getFullYear(), initialDate.getMonth(), day);
    }
    case TimeScale.Days: {
      const day = initialDate.getDate() + timeUnits;
      return new Date(initialDate.getFullYear(), initialDate.getMonth(), day);
    }
  }
};

export type ChartProps = GanttChartProps & {
  onDateChanged?: (interval: Interval) => void;
  onTimeScaleChanged?: (timeScale: TimeScale) => void;
};

const Chart = (props: ChartProps) => {
  const { onDateChanged, onTimeScaleChanged } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { timeScale, timeUnits, initialDate } = state;

  useEffect(() => {
    onDateChanged && onDateChanged({ start: initialDate, end: calcEndDate(initialDate, timeScale, timeUnits) });
  }, [initialDate, timeScale, timeUnits]);

  useEffect(() => {
    onTimeScaleChanged && onTimeScaleChanged(timeScale);
  }, [timeScale]);

  return (
    <Container
      header={
        <div style={css.header}>
          <SpaceBetween direction="horizontal" size="s">
            <Button iconName="zoom-out" ariaLabel="zoom out" variant="icon" onClick={() => dispatch({ type: 'zoomOut' })} />
            <Button iconName="zoom-in" ariaLabel="zoom in" variant="icon" onClick={() => dispatch({ type: 'zoomIn' })} />
          </SpaceBetween>
          <SpaceBetween direction="horizontal" size="s">
            <Button iconName="angle-left" ariaLabel="previous" variant="icon" onClick={() => dispatch({ type: 'prevDate' })} />
            <Button iconUrl="today-outline.svg" ariaLabel="today" variant="icon" onClick={() => dispatch({ type: 'today' })} />
            <Button iconName="angle-right" ariaLabel="next" variant="icon" onClick={() => dispatch({ type: 'nextDate' })} />
          </SpaceBetween>
          <Box>
            <Tabs
              tabs={[
                {
                  label: 'Days',
                  id: TimeScale.Days,
                },
                {
                  label: 'Weeks',
                  id: TimeScale.Weeks,
                },
                {
                  label: 'Months',
                  id: TimeScale.Months,
                },
              ]}
              activeTabId={timeScale}
              onChange={({ detail }) => dispatch({ type: 'changeScale', payload: detail.activeTabId })}
              disableContentPaddings={true}
            />
          </Box>
        </div>
      }
    >
      <GanttChart
        timeScale={timeScale}
        units={timeUnits}
        initialDate={initialDate}
        {...props}
        onZoomToDate={(scale: TimeScale, beginDate: Date, timeUnit: number) => {
          dispatch({
            type: 'zoomTo',
            payload: { initialDate: beginDate, timeScale: scale, timeUnits: timeUnit },
          });
        }}
      />
    </Container>
  );
};

const css = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default Chart;
