import {PropertyFilterProps} from '@cloudscape-design/components';

export const projectFilteringProperties:PropertyFilterProps.FilteringProperty[]=[
  {
    key: "engagementId",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Engagement Id",
    groupValuesLabel: "Engagement Id values"
  },
  {
    key: "em",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Engagement Manager",
    groupValuesLabel: "Engagement Manager values"
  },
  {
    key: "name",
    operators: ["=", "!=", ":", "!:"],
    propertyLabel: "Name",
    groupValuesLabel: "Name values"
  },
  {
    key: "confidence",
    operators: ["<=", ">=", "=", "!=", ">", "<" ],
    propertyLabel: "Confidence",
    groupValuesLabel: "Confidence values"
  },
  {
    key: "region",
    operators: ["=", "!="],
    propertyLabel: "Region",
    groupValuesLabel: "Region values"
  },
  {
    key: "projectType",
    operators: ["=", "!="],
    propertyLabel: "Engagement Type",
    groupValuesLabel: "Engagement Type values"
  },
  {
    key: "projectCategory",
    operators: ["=", "!="],
    propertyLabel: "Engagement Category",
    groupValuesLabel: "Engagement Category values"
  },
  {
      key: "assignment",
      operators: ["="],
      propertyLabel: "Has assignment",
      groupValuesLabel: "Assignment values"
  },
];

export const nameOperator = (a:string) => (str:string) => (b:string) => {
  switch(str) {
    case '=': return a.localeCompare(b) === 0
    case '!=': return a.localeCompare(b) !== 0
    case ':': return a.includes(b)
    case '!:': return !a.includes(b)
    default: return "Invalid Operation"
  }
};

export const emOperator = (a: string|undefined) => (str:string) => (b:string) => {
  return (() => {
    switch (str) {
      case '=':
        return a && a.localeCompare(b) === 0
      case '!=':
        return !a || a.localeCompare(b) !== 0
      case ':':
        return a && a.includes(b)
      case '!:':
        return !a || !a.includes(b)
      default:
        return "Invalid Operation"
    }
  })();
};

export const regionOperator = (a:string) => (str:string) => (b:string) => {
  switch(str) {
    case '=': return b.localeCompare(a) === 0
    case '!=': return b.localeCompare(a) !== 0
    default: return "Invalid Operation"
  }
};

export const typeOperator = (a:string) => (str:string) => (b:string) => {
  switch(str) {
    case '=': return b.localeCompare(a) === 0
    case '!=': return b.localeCompare(a) !== 0
    default: return "Invalid Operation"
  }
};

export const categoryOperator = (a:string) => (str:string) => (b:string) => {
  switch(str) {
    case '=': return b.localeCompare(a) === 0
    case '!=': return b.localeCompare(a) !== 0
    default: return "Invalid Operation"
  }
};

export const confidenceOperator = (a:number) => (str:string) => (b:number) => {
  switch(str) {
    case '=': return a === b
    case '!=': return a !== b
    case '>': return a > b
    case '<': return a < b
    case '>=': return a >= b
    case '<=': return a <= b
    default: return "Invalid Operation"
  }
}

export const regions = [
  "Benelux", 'DACH', "EM", "EMEA", "France", "Iberia", "Israel", "Italy", "Nordics", "UKI"
]